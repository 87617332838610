import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SocketService } from '@core/services/socket';
import { UserService } from 'src/app/users/services/user-service';


@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  providers   : [ UserService, SocketService ]
})
export class MessengerComponent implements OnInit , AfterViewChecked {
  constructor(
    private user: UserService,
    private router : Router,
    private socket : SocketService,
  ) {}

  public userID            : number // User ID
  public userContacts      : any = []    // User Contacts list

  public openedRoom         : number          // Opened Room ID 
  public contactID          : number          // Opened Room contact ID
  public openedRoomContact  : any      // Opened Room contact Informations
  public openedRoomMessages : any = [] // Opened Room Messages ( send / received )
  public messageToSend      : any = ''     // Opened Room user message ( typing message )
  
  public roomIsLoading      : boolean = true  // Room is loading ? 
  public roomWithNewMessage : any = [] // All Room ID with waiting Message

  public typingTimer     : any             // Timmer to stop "user is typing" informations
  public userIsTyping    : Boolean = false // User is typing message ? 
  public contactIsTyping : Boolean = false // Contact is typing message ? 

  public displayContacts : Boolean = false // RESPONSIVE = toggle slide contacts


  @ViewChild('messenger') myDiv: ElementRef;

  scrollToBottom(): void {
    if(this.myDiv) {
      this.myDiv.nativeElement.scrollTop = this.myDiv.nativeElement.scrollHeight;
    }
    
  }
  ngAfterViewChecked() {  
    if(this.myDiv) {
      this.scrollToBottom();       
    }
  
} 
  ngOnInit(){
    
    if(this.user.getUser()) // Only if user is logged
        { 
            this.userID = this.user.getUser().data.id // Get user ID

            this.socket.connectUser().then( // User is connected to node js via socket
                userIsConnected => {
                    this.initListeners()
                    this.getContacts();
                   
                   
                }, 
               
                userIsNotConnected => console.log('lutilisateur na pas pu etre connecte au chat')
            )
        }
        else this.router.navigate(['login'])
  }

     /*
    * Set socket listeners
    */
     public initListeners(){
      // Supprimer les anciens listeners ( probleme des listeners qui se cummulent )
      this.socket.unsetListeners()

      let socketListener : any = this.socket.getSocket()
      
      //Met à jour les utilisateurs connectés et deconnectés
      socketListener.on('connected_users', (users: any) => {
          this.updateConnectedUsers(users)
      })

      // Quand un nouveau message arrive
      socketListener.on('chat_message', (message: any) => {
          this.setNewMessage(message)                
      })

      // Affiche que le contact de la conversation est entrain de composer un message
      socketListener.on('is_typing', (typing: any) => {
          if( typing.value == true && typing.id == this.contactID)
              this.contactIsTyping = true
          else if( typing.value == false && typing.id == this.contactID )
              this.contactIsTyping = false

              this.scrollToBottom();
      })       
  }

  /*
  * Recupere la liste de contact de l'utilisateur
  */
  public getContacts(){
  
      this.socket.getContactList().then(
          contacts => {
            this.setUserContact(contacts)
            this.userContacts = contacts;
            console.log(this.userContacts)
          }
          
      )
  }

   /*
    * Defini la liste des contacts de l'utilisateur et les rooms qui ont des messages en attente
    */
   public setUserContact(contacts: any){
    contacts.map( (contact: any) => {
         if( contact.count_not_read > 0 && this.roomWithNewMessage.indexOf(contact.id) == -1 )
             this.roomWithNewMessage.push(contact.id)
     })

     this.userContacts = this.reOrderContacts(contacts)

     this.openMessengerRoom(this.userContacts[0])
 }

 /*
 * Remet les contacts dans l'ordre -> Message reçu : le plus recent ordre croissant
 */
 public reOrderContacts(contacts: any){
     contacts.sort( ( firstContact: any , secondContact: any ) => {
         // firstContact descend d'un cran dans le tableau ( aucun message pour ce contact )
         if( firstContact.messages.length == 0) return 1

         // firstContact monte d'un cran dans le tableau ( aucun message pour le second contact )
         else if( secondContact.messages.length == 0 ) return -1
         
         // Les deux contacts on des messages 
         else{

             // On supprime les messages envoyés pas l'utilisateur pour ne garder que les messages reçus des convtacts
             const firstContactReceivedMessages = firstContact.messages.filter((message: any) => {
                 return message.user_id != this.userID
             })

             const secondContactReceivedMessages = secondContact.messages.filter((message: any) => {
                 return message.user_id != this.userID
             })

             // firstContact descend d'un cran dans le tableau (il n'a pas de messages reçus)
             if( firstContactReceivedMessages == 0 ) return 1

             // firstContact monte dun cran dans le tableau ( il n'y a pas de messages reçus pour le second contact )
             else if( secondContactReceivedMessages == 0 ) return -1

             // Les deux contacts on bien envoyé des message à l'utilisateur
             else{
                 const firstContactLastMessageIndex = firstContactReceivedMessages.length -1
                 const firstContactLastMessage      = firstContactReceivedMessages[firstContactLastMessageIndex]
                 const firstContactLastMessageDate  = new Date(firstContactLastMessage.created)

                 const secondContactLastMessageIndex = secondContactReceivedMessages.length -1
                 const secondContactLastMessage      = secondContactReceivedMessages[secondContactLastMessageIndex]
                 const secondContactLastMessageDate  = new Date(secondContactLastMessage.created)

                 // firstContact descend d'un cran dans le tableau ( le dernier message reçu est plus vieux )
                 if(firstContactLastMessageDate < secondContactLastMessageDate) return 1

                 // firstContact monte dun cran dans le tableau ( le dernier message reçu est plus recent )
                 else return -1
             }
         }
     })

     return contacts
 }

 /*
 * Change de room
 */
 public openMessengerRoom(contact: any){
     this.roomIsLoading      = true
     this.openedRoomMessages = []
     this.openedRoom         = contact.id
     this.contactID          = contact.user.id
     this.openedRoomContact  = contact

     this.socket.openRoom(this.openedRoom).then( history => {
         console.log('history', history)

         this.openedRoomMessages = history.messages
         this.roomIsLoading      = false
        this.scrollToBottom();
         this.roomScrollDown()
         this.removeWaitingMessageFlagForOpenedRoom()
     })
 }

 /*
 * Ajout le nouveau message reçu a la conversion ou à la liste des rooms avec un message en attente
 */
 public setNewMessage(message: any){
     message.created = new Date().toISOString()

     // Ajout du messages aux messages des contacts pour réordoner la liste des contacts
     this.userContacts = this.userContacts.map((contact: any) => {
         if(contact.id == message.conversation_id) 
             contact.messages.push(message)

         return contact
     })

     // Ré-ordone les contacts
     this.userContacts = this.reOrderContacts(this.userContacts)
    

 if(message.conversation_id == this.openedRoom){
   this.openedRoomMessages.push(message)

         this.roomScrollDown()
         this.removeWaitingMessageFlagForOpenedRoom()
 }
 else
   this.roomWithNewMessage.push(message.conversation_id)
 }

 /*
 * Supprimer la conversation en cours ( room ) de la liste des conversation qui ont des messeges en attente
 */
 public removeWaitingMessageFlagForOpenedRoom(){
     const indexOfRoom = this.roomWithNewMessage.indexOf(this.openedRoom)

     if(indexOfRoom > -1) 
         this.roomWithNewMessage.splice(indexOfRoom, 1)
 }

 /*
 * Permet de garder le scroll toujours au niveau bas de la conversation ( le timout est un quickfix ...)
 */
 public roomScrollDown(){
     setTimeout( () => 
     {
        //  const roomWrapper = jQuery(".messenger__messages__wrapper")
        //  const scroll      = jQuery(".messenger__messages__wrapper .ps-content").height()

        //  roomWrapper.animate({ scrollTop:  scroll }, 300);
     }, 0.1)
 }

 /*
 * Met à jour la liste des utilisateurs connectés
 */
 public updateConnectedUsers(users: any){
   let connected: any = []

 // Recupere l'id des utilisateurs connectés à messenger
 users.map((user: any) =>{
   if (user.isConnected == true)
     connected.push(user.id)
 })

 // Compare la liste des contacts de l'utilisateur au id connecté et met à jour la liste en fonction
 if(this.userContacts && this.userContacts.length > 0){
         const temp = this.userContacts.map((contact : any)=>{
             if( connected.indexOf(contact.user.id) >= 0 )
                 contact.isConnected = true
             else 
                 contact.isConnected = false

             return contact
         })
         
         this.userContacts = temp
     }   
 }

 /*
 * L'utilisateur envoi un message
 */
 public sendMessage(){
    
      this.messageToSend = this.messageToSend.trim()
      console.log(this.messageToSend)
      if( this.messageToSend != '' ){
          const message = {
              message : this.messageToSend,
              to      : this.openedRoom
          }
          
            this.socket.emit('chat_message', message)
            
          // RAZ du message
          delete this.messageToSend    
      }
 }

 /*
 * Ajoute un emoji au message en cours
 */
 public addEmoji(emoji: any){
     if(this.messageToSend == undefined)
         this.messageToSend = ''

   switch (emoji) {
     case ":)":
       this.messageToSend +="🙂" 
       break;
     case ";)":
       this.messageToSend +="😉" 
       break;
     case ":D":
       this.messageToSend +="😀"
       break;
   case ":')":
       this.messageToSend +="😂" 
       break;
     case "<3":
       this.messageToSend +="😍"
       break;
     case ":p":
       this.messageToSend +="😜" 
       break;
     case ":(":
       this.messageToSend +="😟" 
       break;
     case ":o":
       this.messageToSend +="😮" 
       break;
     case ":'(":
       this.messageToSend +="😢" 
       break;
   }
 }

 public onValueChange(event: any) {
 
  this.messageToSend = event.target.value;
 
 }

 /*
 * Quand l'utilisateur est entrain de composer un message
 */
 public isTyping(key: any){
  this.messageToSend = key.target.value;
 
     if (key.which === 13){
     
         this.socket.emit('stop_typing', {id: this.userID});
         this.userIsTyping = false;
         this.sendMessage()
     }
     else{
      console.log(this.userIsTyping)
         // Si l'utilisateur n'etait pas entrain de taper, alors on indique au socket que l'utilisateur est entrain de taper
         if ( !this.userIsTyping ) {
          console.log('userIsTyping')
             this.socket.emit('start_typing', {id: this.userID});
             this.userIsTyping = true;
         }
         console.log('else')
         // Si l'utilisateur ne touche plus le clavier pendant 1.5 seconde alors on indique au socket que l'utilisateur n'est plus entrain de taper
         clearTimeout(this.typingTimer)
         this.typingTimer = setTimeout( () => {
             this.socket.emit('stop_typing', {id: this.userID});
             this.userIsTyping = false;
         }, 1500); 
     }
 }

}