import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service';

@Injectable()
export class HomeService {
  constructor(private requestmanager: RequestManagerService) {}

  getPosts(url: string) {
    return this.requestmanager.getBasic(url);
  }

  postArticleSeen(link: string) {
    return this.requestmanager.post(link, null, true);
  }

  postLinksSeen(link: string) {
    return this.requestmanager.post(link, null, true);
  }

  getArticle(article: any) {
    return this.requestmanager.getBasic('contents/articles/' + article);
  }
}
