import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-invitation-signin-buttons',
  templateUrl: './invitation-signin-buttons.component.html',
  styleUrls: ['./invitation-signin-buttons.component.scss'],
})
export class InvitationSigninButtonsComponent {
  @Output() cliquedButton = new EventEmitter();

  public button(button: any) {
    this.cliquedButton.emit(button);
  }
}
