import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { passwordMatchingValidatior } from '@core/validators/confirmPassword';
import * as moment from 'moment';
import { MessageComponent } from 'src/app/core/components/message/message.component';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-patient-password',
  templateUrl: './patient-password.component.html',
  styleUrls: ['./patient-password.component.scss'],
  providers: [MessageComponent],
})
export class PatientPasswordComponent implements OnInit {
  @Input() patientID: any;
  passwordForm: FormGroup;
  public validationMessage: any;
  public submitted = false;
  public submitButtonState: string = 'save';

  public newPassword: string;
  public newPasswordConfirmed: Boolean = false;

  public userPassword: string;
  public passwordConfirmed: any;

  public patient: any;

  constructor(private user: UserService, private message: MessageComponent) {
    this.passwordForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(4),
        ]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      { validators: passwordMatchingValidatior }
    );
  }
  ngOnInit(): void {
    this.patient = this.user.getUserPatient(this.patientID)[0];
  }

  /*
   * Recupere le resultat du component password-tester
   */
  public userPasswordConfirmed(result: any) {
    this.passwordConfirmed = result;
  }

  /*
   * Recupere le password et le "pass" ( true / false ) du component password-confirm
   */
  public changePassword(password: any) {
    this.newPassword = password.password;
    this.newPasswordConfirmed = password.pass;
  }

  /*
   * Enregistre le nouveau mot de passe
   */
  public validate() {
    if (this.passwordConfirmed && this.newPasswordConfirmed) {
      // this.toggleSubmitButton();

      this.patient.birthday = moment(this.patient.birthday).format(
        'DD/MM/YYYY'
      );
      this.patient.password = this.newPassword;

      this.user
        .updateUser(this.patient.id, this.patient)
        .subscribe((serverResponse: any) => {
          const response = serverResponse;

          const user = {
            username: response.data.username,
            password: this.user.getUser().password,
          };

          this.user.setUser(user.username, user.password, response.data);
          this.validationMessage = this.message.success(response.message);
        });
    }
  }
}
