import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageComponent } from 'src/app/core/components/message/message.component';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-patient-contacts',
  templateUrl: './patient-contacts.component.html',
  styleUrls: ['./patient-contacts.component.scss'],
  providers: [MessageComponent],
})
export class PatientContactsComponent implements OnInit {
  @Input() patientID: number;
  contactForm: FormGroup;
  public submitted = false;
  public patient: any;
  public waitingContacts: Array<any> = [];
  public approvedContacts: Array<any> = [];
  public contactToDelete: any;
  public alertMessage = {
    status: false,
    invitation: {},
    deleteContact: {},
  };

  public modalDelete: boolean = false;

  private invitedContact = {
    email: '',
    message: '',
  };

  public loading = {
    waitingContacts: false,
    approvedContacts: false,
    removeContact: false,
  };

  constructor(private user: UserService, private message: MessageComponent) {}

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      patient_id: new FormControl(this.patientID, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      comment: new FormControl(null, [Validators.required]),
    });
    this.generateDefaultContactMessage();
    this.getWaitingContacts();
    this.getApprovedContacts();
  }

  /*
   * Surveille le changement du parametre 'patientID'
   * Lance la recuperation des contacts et genere le message par default de la demande de contact
   */
  ngOnChanges() {
    this.generateDefaultContactMessage();
    this.getWaitingContacts();
    this.getApprovedContacts();
  }

  /*
   * Genere le message par defaut de la demande de contact
   */
  private generateDefaultContactMessage() {
    this.patient = this.user.getUserPatient(this.patientID)[0];
    const representant = this.user.getUser().data;

    const comment =
      'Bonjour,\n\n' +
      this.patient.firstname +
      " est actuellement à l'hôpital, tu peux le/la contacter en t'inscrivant sur la plateforme PRIMAKID.\n\nA Bientôt,\n" +
      representant.firstname +
      ' ' +
      representant.lastname;

    this.contactForm.patchValue({ comment });
  }

  /*
   * Recuepere les contacts en attente du patient
   */
  public getWaitingContacts() {
    this.loading.waitingContacts = true;

    this.user.getPatientInvited(this.patientID).subscribe((contacts: any) => {
      this.waitingContacts = contacts.data;
      this.loading.waitingContacts = false;
    });
  }

  /*
   * Recuepere les contacts actifs du patient
   */
  public getApprovedContacts() {
    this.loading.approvedContacts = true;

    this.user.getContactValidated(this.patientID).subscribe((contacts: any) => {
      this.approvedContacts = contacts.data;

      this.loading.approvedContacts = false;
    });
  }

  /*
   * Verifie que le contact soit ou non celui du representant légale de l'enfant
   */
  public isRepresentantContact(contactID: any) {
    const representantID = this.user.getUser().data.id;
    let isRepresentant: Boolean;

    representantID == contactID
      ? (isRepresentant = true)
      : (isRepresentant = false);

    return isRepresentant;
  }

  /*
   * Selectionne le contact à supprimer
   */
  public selectContactToDelete(contact: any) {
    this.modalDelete = true;
    this.contactToDelete = contact;
  }

  /*
   * Verifie si le contact à ajouter n'est pas déja dans la liste des contacts en attente
   */
  private contactIsAlreadyWaiting() {
    const test = this.waitingContacts.filter((contact) => {
      return contact.email == this.invitedContact.email;
    });

    if (test.length > 0) return true;
    else return false;
  }

  public closeModal() {
    this.modalDelete = false;
  }

  stopPropagation(e: any) {
    e.stopPropagation();
  }

  /*
   * Supprime un contact du tableau des contacts actifs
   */
  private removeApprovedContactFromArray(id: any) {
    this.approvedContacts = this.approvedContacts.filter((contact) => {
      if (contact.id != id) return contact;
    });
  }

  /*
   * Supprime un contact actif
   */
  public deleteContact(id: any) {
    if (!this.loading.removeContact) {
      this.loading.removeContact = true;
      this.user.deleteContactActive(this.patientID, id).subscribe(
        (response: any) => {
          this.closeModal();
          this.removeApprovedContactFromArray(id);
          this.loading.removeContact = false;
        },
        (error: any) => {
          const response = error.json();
          this.loading.removeContact = false;
          this.closeModal();
          this.alertMessage.deleteContact = this.message.error(
            response.message
          );
        }
      );
    }
  }

  validate() {
    this.submitted = true;
    this.alertMessage.status = false;
    if (this.contactForm.valid) {
      this.user.postInvitation(this.contactForm.value).subscribe(
        (success: any) => {
          if (this.contactIsAlreadyWaiting()) {
            this.alertMessage.status = true;
            this.alertMessage.invitation = this.message.success(
              "Renvoi d'une invitation à " + this.invitedContact.email + ' .'
            );
          } else {
            this.alertMessage.status = true;
            this.waitingContacts.push({ email: this.contactForm.value.email });
            this.alertMessage.invitation = this.message.success(
              success.message
            );
          }
        },
        (error: any) => {
          this.alertMessage.status = true;
          this.alertMessage.invitation = this.message.error(
            error.error.message
          );
        }
      );
    }
  }
}
