import { Component, OnInit }              from '@angular/core'
import {  Router, ActivatedRoute } from '@angular/router'
import { UserService } from '../../services/user-service'
import { DateConverte } from 'src/app/core/services/dateConvert'
import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service'

@Component({
	selector: 'app-disabled-account',
	templateUrl: './disabled-account.component.html',
	styleUrls: ['./disabled-account.component.scss'],
})

export class DisabledAccountComponent implements OnInit {

	public userToken : any
	public button    : any = {
		text     : 'réactiver mon compte',
		disabled : false
	}
	
	constructor(
		
		private user           : UserService,
		private router         : Router,
		private route          : ActivatedRoute,
		private dateConverte : DateConverte,
		private request: RequestManagerService
	){
		// if(this.user.getUser())
		// 	this.router.navigate(['/'])
	}

	/*
	* Ecouteur sur le token ( Recupere le token à chaque modification de token dans l'url )
	*/
	ngOnInit(){
		const params = this.route.snapshot.paramMap.get('token');
		this.userToken = (params) ? params : null;
		
	}

	/*
	* Renvoi les informations (username et mot de passe ) de connexion decrypté du token
	* Return object {username, password}
	*/
	private decodeUserToken(){
		let user : any = window.atob(this.userToken)
			user = user.split(":");

		let username : string = user[0]
		let password : string = ''

		if(user.length > 2){
			for (var i = 1; i < user.length; i++) 
				password += ':'+user[i]

			password =  password.slice(1, -1)
		}
		else password = user[1]

		return {
			username,
			password
		}		
	}

	/*
	* Active le compte du representant légal
	* Return promise 
	*/
	public enableRepresentantAccount(data: any){
		
		
		let representant          = data
		
		representant.birthday = this.dateConverte.toDMY(representant.birthday)
		representant.status   = 'active'

		delete representant.patients
		
		this.user.updateUser('users/'+representant.id, representant).subscribe((response: any) => {
			
			this.router.navigate(['./representant']) 
		}, error => {
			
			this.button = {
				text     : 'réactiver mon compte',
				disabled : false
			}
		})
	
	}

	/*
	* Lance l'activation du compte via le boutton de l'interface 
	*/
	public activateAccount(){
		this.button = {
			text     : 'Activation en cours...',
			disabled : true
		}
		const user = this.decodeUserToken()
		this.user.Login(user).subscribe((response: any) => {
			this.enableRepresentantAccount(response.data);
		})

		
		
		
	}
}
