import { FormControl } from '@angular/forms';

export function telephoneValidator(control: FormControl): { [key: string]: boolean } | null {
  const telephoneRegex = /^\d{10}$/; // Expression régulière pour vérifier si le numéro a exactement 10 chiffres

  if (control.value && !telephoneRegex.test(control.value)) {
    console.log('pas bon')
    return { 'telephoneInvalide': true }; // Retourne un objet avec une propriété 'telephoneInvalide' si le numéro est invalide
  }

  return null; // Retourne null si le numéro est valide
}