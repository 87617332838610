import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { passwordMatchingValidatior } from '@core/validators/confirmPassword';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-invitation-signin-form',
  templateUrl: './invitation-signin-form.component.html',
  styleUrls: ['./invitation-signin-form.component.scss'],
})
export class InvitationSigninFormComponent {
  @Input() invitationEmail: string;

  @Output() isSend = new EventEmitter();

  public form: any;
  public emailExist: boolean = false;
  public passwordConfirmed: Boolean = false;
  public username: any;
  public submitted: boolean = false;
  stepForm: FormGroup;

  constructor(private user: UserService) {}

  /*
   * Instancie parsley sur le formulaire
   */
  ngOnInit() {
    this.stepForm = new FormGroup(
      {
        username: new FormControl(''),
        lastname: new FormControl('', [Validators.required]),
        firstname: new FormControl('', [Validators.required]),
        email: new FormControl(this.invitationEmail, [Validators.required]),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(4),
        ]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      { validators: passwordMatchingValidatior }
    );
  }

  /*
   * Modifie l'email
   */

  changeEmail(event: any) {
    const email = event.target.value;

    this.user.emailIsAssigned(email).subscribe((response: any) => {
      if (response.data) {
        this.emailExist = email.isAssigned;
      }
    });
  }

  usernameSend(event: any) {
    this.username = event;
    this.stepForm.value.username = event;
  }

  onSubmit() {
    console.log('submit');
    this.submitted = true;
  }
}
