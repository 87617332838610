<div [class.inputspinnerloading]="loading > 0">
  <label class="m-label">Identifiant</label>
  <input
    type="text"
    name="username"
    disabled="disabled"
    class="m-input"
    [(ngModel)]="username"
  />
  <div class="input-spinner"></div>
</div>
