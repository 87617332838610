import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../services/home-service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent {
  public articleID: any;
  public article: any;
  public loading: boolean = false;
  public imgLinkUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private home: HomeService
  ) {}

  /*
   * Surveille le changement du parametre id dans l'url
   */
  ngOnInit() {
    this.articleID = this.route.snapshot.paramMap.get('id');
    this.getArticle();
  }

  /*
   * Affiche ou masque le chargement
   */

  private toggleLoadder() {
    this.loading ? (this.loading = false) : (this.loading = true);
  }

  /*
   * Recupere les informations du patient
   */
  private getArticle() {
    this.toggleLoadder();
    this.home.getArticle(this.articleID).subscribe(
      (x: any) => {
        this.article = x.data;

        this.toggleLoadder();
        console.log(this.loading);
      },
      (error: any) => {
        this.toggleLoadder();
      }
    );
  }
}
