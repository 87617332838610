import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from 'src/core/core.module';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LoginComponent } from './users/components/login/login.component';
import { HeaderComponent } from './layouts/components/header/header.component';
import { FooterComponent } from './layouts/components/footer/footer.component';
import { SubscribeComponent } from './users/components/subscribe/subscribe.component';
import { SubscribeSuccessComponent } from './users/components/subscribe-success/subscribe-success.component';
import { Step1Component } from './users/components/subscribe/step1/step1.component';
import { Step2Component } from './users/components/subscribe/step2/step2.component';
import { Step3Component } from './users/components/subscribe/step3/step3.component';
import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service';
import { TokenService } from '@core/services/token-service/token.service';
import { UserService } from './users/services/user-service';
import { FacilitiesListComponent } from './users/components/facilities-list/facilities-list.component';
import { FacilitiesService } from './users/services/facilities-service';
import { UsernamePredict } from '@core/components/username-predict/username-predict.component';
import { InvitationTools } from './users/services/invitation-service';
import { ConnectedComponent } from './layouts/components/header/connected/connected.component';
import { Config } from '@core/services/config';
import { RepresentantComponent } from './users/components/representant/representant.component';
import { RepresentantInformationsComponent } from './users/components/representant/representant-informations/representant-informations.component';
import { MessageComponent } from './core/components/message/message.component';
import { RepresantantPasswordComponent } from './users/components/representant/represantant-password/represantant-password.component';
import { PasswordTesterComponent } from './core/components/password-tester/password-tester.component';
import { PasswordConfirmComponent } from './core/components/password-confirm/password-confirm.component';
import { RepresentantStatusComponent } from './users/components/representant/representant-status/representant-status.component';
import { PatientComponent } from './users/components/patient/patient.component';
import { PatientInformationsComponent } from './users/components/patient/patient-informations/patient-informations.component';
import { ServiceSelectComponent } from './core/components/service-select/service-select.component';
import { PatientPasswordComponent } from './users/components/patient/patient-password/patient-password.component';
import { PatientContactsComponent } from './users/components/patient/patient-contacts/patient-contacts.component';
import { InvitationComponent } from './users/components/invitation/invitation.component';
import { InvitationErrorComponent } from './users/components/invitation/invitation-error/invitation-error.component';
import { InvitationConfirmComponent } from './users/components/invitation/invitation-confirm/invitation-confirm.component';
import { InvitationSuccessComponent } from './users/components/invitation/invitation-success/invitation-success.component';
import { InvitationSigninComponent } from './users/components/invitation/invitation-signin/invitation-signin.component';
import { InvitationSigninButtonsComponent } from './users/components/invitation/invitation-signin/invitation-signin-buttons/invitation-signin-buttons.component';
import { InvitationSigninFormComponent } from './users/components/invitation/invitation-signin/invitation-signin-form/invitation-signin-form.component';
import { HomeComponent } from './home/components/home/home.component';
import { HomeService } from './home/components/services/home-service';
import { ArticleComponent } from './home/components/article/article.component';
import { MessageDatePipe } from '@core/pipes/messageDate'; 
import { SocketService } from '@core/services/socket'; 
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { MessengerComponent } from './messenger/components/messenger/messenger.component';
import { DisabledAccountComponent } from './users/components/disabled-account';
import { DateConverte } from './core/services/dateConvert';





const config: SocketIoConfig = { url: 'https://chat.primakid.net', options: {} };


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    SubscribeComponent,
    SubscribeSuccessComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    FacilitiesListComponent,
    UsernamePredict,
    ConnectedComponent,
    RepresentantComponent,
    RepresentantInformationsComponent,
    MessageComponent,
    RepresantantPasswordComponent,
    PasswordTesterComponent,
    PasswordConfirmComponent,
    RepresentantStatusComponent,
    PatientComponent,
    PatientInformationsComponent,
    ServiceSelectComponent,
    PatientPasswordComponent,
    PatientContactsComponent,
    InvitationComponent,
    InvitationErrorComponent,
    InvitationConfirmComponent,
    InvitationSuccessComponent,
    InvitationSigninComponent,
    InvitationSigninButtonsComponent,
    InvitationSigninFormComponent,
    HomeComponent,
    ArticleComponent,
    MessageDatePipe,
    MessengerComponent,
    DisabledAccountComponent
  ],
  imports: [
    BrowserModule,
    SocketIoModule.forRoot(config),
    CoreModule,
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [
    RequestManagerService,
    TokenService,
    UserService,
    HomeService,
    FacilitiesService,
    InvitationTools,
    SocketService,
    Config,
    DateConverte
   
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
