import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvitationTools } from 'src/app/users/services/invitation-service';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-invitation-signin',
  templateUrl: './invitation-signin.component.html',
  styleUrls: ['./invitation-signin.component.scss'],
})
export class InvitationSigninComponent implements OnInit {
  public hash: string;
  public display: string = 'buttons';
  public decodedInvitation: any;

  constructor(
    private user: UserService,
    private route: ActivatedRoute,
    private invitation: InvitationTools
  ) {}

  /*
   * Recupere le hash
   */
  ngOnInit() {
    const params = this.route.snapshot.paramMap.get('hash');
    if (params) {
      this.hash = params;
    }

    this.invitation.read(this.hash).subscribe(
      (decodedInvitation: any) => {
        this.decodedInvitation = decodedInvitation.data;

        if (this.decodedInvitation.username) {
          this.invitation.login(this.hash);
        } else {
          this.decodedInvitation = decodedInvitation.data;
        }
      },
      (error) => {
        this.invitation.displayError(this.hash);
      }
    );
  }

  /*
   * Action en fonction des boutons
   */
  public action(action: any) {
    console.log(this.decodedInvitation);
    switch (action) {
      case 'login':
        this.invitation.login(this.hash);
        break;

      case 'form':
        this.display = 'form';
        break;
    }
  }

  /*
   * Creation du compte contact, acceptation de l'invitation, et auto loggin du contact
   */
  public createContactAccount(contact: any) {
    this.user.postContactConfirm(this.hash, contact).subscribe(
      (success: any) => {
        this.connectContactAccount(
          success.json().data.username,
          contact.password
        );
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  /*
   * Connecte le compte du contact
   */
  private connectContactAccount(username: any, password: any) {
    const user = {
      password: password,
      username: username,
    };
    this.user.Login(user).subscribe((success) => {
      console.log(success);
    });

    // this.requestManager.get('users/authenticate', user).then(
    // 	success => {
    // 		this.user.refreshUserData(user).then(
    // 			success => this.invitation.success(this.hash)
    // 		)
    // 	}
    // )
  }
}
