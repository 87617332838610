<div class="main">
  <div class="head">
    <div class="container">
      <p class="title">
        LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
      </p>
    </div>
  </div>
  <div class="banner">
    <div class="container">
      <div class="bannerWrapper">
        <div><img src="assets/images/login--character.png" /></div>
        <div>
          <h1 class="bannerTitle">
            Inscrivez vous et <strong>votre enfant</strong> à PRIMAKID
          </h1>
        </div>
      </div>
    </div>
  </div>
  <div class="form">
    <div class="container">
      <div class="formWrapper">
        <div class="formInfos">
          <img src="assets/images/informations.png" />
          <p class="formInfosTitle">
            <strong>PRIMAKID, c’est un ordinateur</strong> avec un accès
            <strong>internet gratuit et sécurisé</strong>, doté d’une interface
            <strong>adaptée à l’âge de l’enfant</strong> qui permet de :
          </p>
          <ul>
            <li>
              Communiquer par mail avec des correspondants autorisés, envoyer et
              recevoir des pièces jointes
            </li>
            <li>
              Stocker des documents (photos, musiques, cours…) sur un espace
              personnel
            </li>
            <li>Écouter de la musique, regarder des DVD</li>
            <li>Surfer sur des sites autorisés par les administrateurs</li>
            <li>
              Jouer à des jeux adaptés à l’âge de l’enfant, validés par les
              administrateurs
            </li>
            <li>
              Profiter des services mis à disposition par les structures
              hospitalières et les associations partenaires.
            </li>
            <li>
              Poursuivre leurs activités habituelles, scolaires, ludiques…
            </li>
            <li>Accéder aux sites de musique et de vidéos</li>
          </ul>
        </div>
        <div  class="formForm">
          <div class="stepsWrapper">
            <p>étape</p> 
            <ul class="steps">
              
              <li [hidden]="addPatientMode && step ===1" *ngFor="let step of steps"  (click)="displayStep(step)">
              
                  <span
                    class="hex"
                    [class.active]="step === displayedStep"
                  ></span>
                  <span class="number">{{ step }}</span>
               
              </li>
            </ul>
          </div>

          <app-step1
            *ngIf="displayedStep == 1 && subscribe"
            (changeStep)="getRepresentantValues($event)"
            [representant]="subscribe.representant"
          ></app-step1>

          <app-step2
            *ngIf="displayedStep == 2 && subscribe"
            [addPatientMode]="addPatientMode"
            (changeStep)="getPatientsValues($event)"
            [patient]="subscribe.patients" 
          ></app-step2>

          <app-step3
            *ngIf="displayedStep == 3"
            [addPatientMode]="addPatientMode"
            (changeStep)="getHonor($event)"
            [patients]="subscribe.patients"
          ></app-step3>
        </div>
      </div>
    </div>
  </div>
</div>
