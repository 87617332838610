<div>
  <div>
    <div class="box">
      <button
        (click)="openModal('disabled')"
        class="m-button m-button--small m-button--border-blue"
      >
        {{ disableAccountBtnText }}
      </button>

      <p>
        Votre compte ainsi que les comptes jeune rattachés seront suspendus.
        Utilisez ce bouton pour empêcher l'accès à Primakid depuis les comptes
        jeunes que vous gérez. Vous pourrez réactiver les comptes à tout moment
        en vous reconnectant et en utilisant le bouton de réactivation.
      </p>
    </div>

    <div class="box" (click)="stopPropagation($event)">
      <button
        (click)="openModal('delete')"
        class="m-button m-button--small m-button--border-red"
      >
        {{ deleteAccountBtnText }}
      </button>

      <p>
        Votre compte ainsi que les comptes jeunes rattachés seront supprimés.
        Utilisez ce bouton pour supprimer définitivement toutes les données qui
        vous concernent ainsi que celles des jeunes rattachés. Cette opération
        est irréversible.
      </p>
    </div>
  </div>
</div>

<div (click)="closeModal()" *ngIf="modalDisable" class="m-modal">
  <div class="m-modal__box" (click)="stopPropagation($event)">
    <button (click)="closeModal()" type="button" class="m-modal__close">
      Fermer
    </button>
    <div class="m-modal__wrapper">
      <p class="m-modal__title">
        Êtes-vous sûr de vouloir désactiver votre compte ?
      </p>
      <p class="m-modal__text">
        Vous ne pourrez plus vous connecter à votre compte PRIMAKID. Vous aurez
        la possibilité de réactiver votre compte.
      </p>
      <p class="m-modal__button">
        <button (click)="disableAccount()" class="m-button m-button--small m-button--border-blue">
          {{ disableAccountBtnText }}
        </button>
      </p>
    </div>
  </div>
</div>

<div (click)="closeModal()" *ngIf="modalDelete" class="m-modal">
  <div class="m-modal__box"  (click)="stopPropagation($event)">
    <button type="button" (click)="closeModal()" class="m-modal__close">
      Fermer
    </button>
    <div class="m-modal__wrapper">
      <p class="m-modal__title">
        Êtes-vous sûr de vouloir supprimer votre compte ?
      </p>
      <p class="m-modal__text">
        Vous ne pourrez plus vous connecter à votre compte PRIMAKID. Vous
        n'aurez la possibilité de réactiver votre compte.
      </p>
      <p class="m-modal__button">
        <button (click)= "deleteAccount()" class="m-button m-button--small m-button--border-red">
          {{ deleteAccountBtnText }}
        </button>
      </p>
    </div>
  </div>
</div>
