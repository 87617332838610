import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'username-predict',
  templateUrl: 'username-predict.html',
  providers: [UserService],
})
export class UsernamePredict implements OnInit {
  @Input() firstname: string;
  @Input() lastname: string;
  @Input() username: string;

  @Output() usernameSend = new EventEmitter();

  private isInEditMode: Boolean = false;
  private componentIsInit: Boolean = false;
  public loading: number = 0;

  // Contient les parametres de l'utilisateur transmit initialement ( en cas d'edition )
  private originalUser: any = {
    firstname: '',
    lastname: '',
    username: '',
  };

  // Contient les parametres de l'utilisateur en cours de modification/création
  private editedUser: any = {
    firstname: '',
    lastname: '',
    username: '',
  };

  constructor(private user: UserService) {}

  /*
   * Si l'utilisateur à déja un useranme ( edition ), on enregistre les informations initiales
   */
  ngOnInit() {
    if (this.username) {
      this.isInEditMode = true;
      this.originalUser.firstname = this.firstname.toUpperCase();
      this.originalUser.lastname = this.lastname.toUpperCase();
      this.originalUser.username = this.username;
    }

    this.componentIsInit = true;
  }

  /*
   * Au changement des parametres on met a jour l'objet user
   */
  ngOnChanges() {
   
    this.editedUser.firstname = this.firstname.toUpperCase();
    this.editedUser.lastname = this.lastname.toUpperCase();
    this.editedUser.username = this.username;

    if (
      this.editedUser.firstname != '' &&
      this.editedUser.lastname != '' &&
      this.componentIsInit
    )
      this.getUsername();
  }

  /*
   * Test si l'identité de l'utilisateur a changé
   */
  private identityChange() {
    if (
      this.editedUser.firstname != this.originalUser.firstname ||
      this.editedUser.lastname != this.originalUser.lastname
    )
      return true;
    else return false;
  }

  /*
   * Demande du username au serveur
   */
  private getUsername() {
    // Si le component est chargé en edition d'un utilisateur et que l'utilisateur change son identité
    // Ou si le component n'est pas en mode d'edition ( creation )
    // Alors on demande au serveur de predire le nom d'utilisateur
    if ((this.isInEditMode && this.identityChange()) || !this.isInEditMode) {
      this.loading++;

      this.user
        .predictUsername(this.editedUser.firstname, this.editedUser.lastname)
        .subscribe((predictedUsername: any) => {
          if (this.loading > 0) {
            this.username = predictedUsername.data;
            this.loading--;
            this.usernameSend.emit(predictedUsername.data);
          }
        });
    }
    // Si le component et en mode edition et que l'utilisateur ne change pas son identité le username reste inchangé
    else if (this.isInEditMode && !this.identityChange()) {
      this.loading = 0;
      this.username = this.originalUser.username;
    }
  }
}
