import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { passwordMatchingValidatior } from '@core/validators/confirmPassword';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements  OnInit {
  @Input() addPatientMode: any;
  @Input() patient: any;
  @Output() changeStep = new EventEmitter();

  stepForm: FormGroup;
  public submitted: boolean = false;
  public errorForm: boolean = false;
  public passworConfirmed: boolean = false;
  public displayTeenagerSlice: boolean = false;
  public displayedPatient = 1;
  public patients = [];
  public username: any = '';

  public relations = [
    { id: 1, name: 'Parent' },
    { id: 2, name: 'Tuteur' },
    { id: 3, name: 'Autre' },
  ];

  constructor(
    private user: UserService,
    private elRef: ElementRef
    ) {
   
  }

  ngOnInit(): void {
    const element = this.elRef.nativeElement.querySelector('#formSubscribe');
 
    window.scrollTo({top: element.offsetTop - 170, behavior: 'smooth'});
    this.stepForm = new FormGroup(
      {
        username: new FormControl(this.patient[0].username,[Validators.required]),
        lastname: new FormControl(this.patient[0].lastname,[Validators.required]),
        firstname: new FormControl(this.patient[0].firstname, [Validators.required]),
        birthday: new FormControl(this.patient[0].birthday, [Validators.required]),
        slice: new FormControl(this.patient[0].slice, [Validators.required]),
        role: new FormControl('patient', [Validators.required]),
        etablishment: new FormControl(this.patient[0].etablishment, [Validators.required]),
        service_id: new FormControl(this.patient[0].service_id, [Validators.required]),
        relation: new FormControl(this.patient[0].relation, [Validators.required]),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(4),
        ]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      { validators: passwordMatchingValidatior }
    );
    this.stepForm.valueChanges.subscribe((val) => {
      this.getChildrenSlice(val.birthday);

      this.submitted = false;
    });
  }

  usernameSend(event: any) {
    console.log(event)
    this.username = event;
    this.stepForm.controls['username'].setValue(event);

    this.stepForm.patchValue({ username: event });
  }

  suggestSlice(age: number) {
    
    if (age >= 13) {
      this.displayTeenagerSlice = true;
      this.stepForm.value.slice = '3';
    } else if (age >= 6) {
      this.displayTeenagerSlice = false;
      this.stepForm.value.slice = '2';
    } else {
      this.displayTeenagerSlice = false;
      this.stepForm.value.slice = '1';
    }
  }

  /*
   * Calcule l'age en année d'un enfant
   * Params = birthday : objet Date
   * Retourne = int
   */
  private calculateAge(birthday: any) {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  private getChildrenSlice(birthday: any) {
    if (birthday.length == 10) {
      let date = birthday.split('/');
      date = date[2] + '-' + date[1] + '-' + date[0];
      date = new Date(date);

      const childrenAge = this.calculateAge(date);
      this.suggestSlice(childrenAge);
    }
  }

  serviceSelected(event: any) {
    this.stepForm.patchValue({ service_id: event });
  }
  etablismentSelected(event: any) {
    this.stepForm.patchValue({ etablishment: event });
  }

 
  onSubmit() {
    this.submitted = true;
    if(this.stepForm.errors) {
      this.errorForm = true;
    }
    if (this.stepForm.errors && this.stepForm.errors['notmatched']) {
      this.passworConfirmed = true;
    } else {
      this.passworConfirmed = false;
    }
 
    if (this.stepForm.valid && !this.passworConfirmed) {
      this.changeStep.emit(this.stepForm.value);
    }
  }
}
