import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service';

@Injectable()
export class FacilitiesService {
  constructor(private requestmanager: RequestManagerService) {}

  getEstablishments() {
    return this.requestmanager.get('establishments', null, true);
  }

  getServices() {
    return this.requestmanager.get('services', null, true);
  }
}
