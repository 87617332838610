import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service';

@Injectable()
export class UserService {
  private slice = new Subject();

  /**
   * Envoie d'une Modal
   */
  SliceSend(number: number) {
    this.slice.next(number);
  }

  SliceGet(): Observable<any> {
    return this.slice.asObservable();
  }

  constructor(private requestmanager: RequestManagerService) {}
  getUser() {
    const valueToReturn = localStorage.getItem('user');

    if (valueToReturn == null) return false;
    else return JSON.parse(valueToReturn);
  }

  getUserPatient(patientID: any) {
    const patients = this.getUser().data.patients;

    return patients.filter((patient: any) => {
      if (patient.id == patientID) return patient;
    });
  }

  setUser(username: any, password: any, data: any) {
    let user = {
      username: username,
      password: password,
      data: data,
    };
    console.log(user);
    localStorage.setItem('user', JSON.stringify(user));
  }


  refreshUserData(user: any = undefined) {
    if (typeof user == "undefined") {
      user = {
        username: this.getUser().username,
        password: this.getUser().password,
      };
    }

    this.Login(user).subscribe((x: any) => {
        user.data = x.data;
       
        localStorage.setItem('user', JSON.stringify(user));
    })
    
  }

  emailIsAssigned(email: any) {
    return this.requestmanager.get('users/exists-email/' + email, null, true);
  }

  setUserPassword(password: any, data: any) {
    let user = {
      username: this.getUser().username,
      password: password,
      data: data,
    };

    localStorage.setItem('user', JSON.stringify(user));
  }

  unsetUser() {
    localStorage.removeItem('user');
    localStorage.removeItem('slice_overide');
  }

  predictUsername(firstname: any, lastname: any) {
    return this.requestmanager.get(
      'users/predict-username/' + firstname + '/' + lastname,
      null,
      true
    );
  }

  SubscribeUsers(data: any) {
    return this.requestmanager.post('users/subscribe.json', data, true);
  }

  Login(data: any) {
    return this.requestmanager.getBasic('users/authenticate', data);
  }

  updateUser(id: any, data: any) {
    return this.requestmanager.put('users/' + id, data, true, true, true);
  }

  checkPassword(user: any) {
    return this.requestmanager.getBasic('users/authenticate', user);
  }

  getPatientInvited(id: any) {
    return this.requestmanager.getBasic('patients/' + id + '/contacts/invited');
  }

  getContactValidated(id: any) {
    return this.requestmanager.getBasic('patients/' + id + '/contacts');
  }

  postInvitation(invitation: any) {
    return this.requestmanager.post(
      'contacts/invite',
      invitation,
      true,
      true,
      true
    );
  }

  deleteContactActive(patientId: any, id: any) {
    return this.requestmanager.delete(
      'patients/' + patientId + '/contacts/' + id,
      true,
      true,
      true
    );
  }

  deleteUser(userId: any) {
    return this.requestmanager.delete(
      'users/'+ userId,
      true,
      true,
      true
    );
  }


  


  postContactConfirm(hash: any, contact: any) {
    return this.requestmanager.post(
      'contacts/confirm/' + hash + '/create',
      contact,
      true,
      true,
      true
    );
  }
}
