import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input() message: any = {
    hash: '',
    message: '',
    type: '',
  };

  public messageClass: any;
  public timeout: any;
  public displayed = false;

  ngOnChanges() {
    if (typeof this.message != 'undefined' && this.message != '')
      this.showMessage();
  }

  /*
   * Creer un message de succes
   */
  public success(message: string) {
    return {
      hash: new Date().getTime(),
      message: message,
      type: 'success',
    };
  }

  /*
   * Creer un message d'erreur
   */
  public error(message: string) {
    return {
      hash: new Date().getTime(),
      message: message,
      type: 'danger',
    };
  }

  public showMessage() {
    this.defineMessageClass();

    clearTimeout(this.timeout);

    this.displayed = true;

    this.timeout = setTimeout(() => {
      this.displayed = false;
    }, 10000);
  }

  public defineMessageClass() {
    switch (this.message.type) {
      case 'success':
        this.messageClass = 'message--success';
        break;
      case 'danger':
        this.messageClass = 'message--danger';
        break;
      default:
        console.log('type de message non reconnu');
        break;
    }
  }
}
