<header class="main">
  <div class="container">
    <div class="wrapper">
     
      <a
        class="external-link"
        href="http://www.asso-prima.org/"
        target="_blank"
      >
        <img src="assets/images/informations.png" />
        <span>Prima, c'est quoi ?</span>
      </a>
      <div class="logo">
        <a [routerLink]="['/']">
          <img src="assets/images/header--primakid-navbar.png" />
        </a>
      </div>

      <a
        class="register"
        [routerLink]="['./login']"
        *ngIf="!configuration.user"
      >
        <img src="assets/images/legal.png" />
        <span>Connexion <br />Inscription</span>
      </a>

      <div *ngIf="configuration.user" class="connected">
        <app-connected
          (slice)="slice($event)"
          [connectedUser]="configuration.user"
          [isHome]="configuration.home"
          [isMessenger]="configuration.messenger"
        ></app-connected>
      </div>

      
    
    </div>
  </div>
</header>
<div *ngIf="!configuration.user && configuration.home"class="banner">
  <div class="container">
    <div class="bannerWrapper">
      <img src="assets/images/prima-home.png" />

      <h1>
        Ton accès web <span>gratuit et sécurisé</span> ! pour jouer, apprendre
        et communiquer :-)
      </h1>
    </div>
  </div>
</div>
