import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvitationTools } from '../../services/invitation-service';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css'],
})
export class InvitationComponent {
  constructor(
    private user: UserService,
    private route: ActivatedRoute,
    private invitation: InvitationTools
  ) {}

  public hash: any;
  /*
   * Ecouteur sur le Hash de l'url
   */
  ngOnInit() {
    const params = this.route.snapshot.paramMap.get('hash');
    this.hash = params;
    this.invitation.read(params).subscribe(
      (success: any) => {
        console.log(success);
        this.redirectUser(success);
      },
      (error: any) => {
        console.log(error);
        this.invitation.displayError(params);
      }
    );
  }

  /*
   * Redirige l'utilisateur en fonction de si il est loggé ou non et si l'invitation est ciblé ou non
   */
  private redirectUser(hash: any) {
    const username = hash.username;
    if (this.user.getUser()) {
      this.invitation.confirm(this.hash);
    } else if (username) {
      this.invitation.login(this.hash);
    } else {
      this.invitation.signin(this.hash);
    }
  }
}
