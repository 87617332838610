<div class="main">
  <p
    *ngIf="connectedUser.data.role == 'representant'"
    (click)="toogleMenu()"
    class="status"
  >
    <img src="assets/images/legal.png" />
    <span
      >espace<br />
      représentant légal</span
    >
  </p>
  <ul
    *ngIf="connectedUser.data.role == 'representant'"
    (click)="toogleMenu()"
    [class.active]="displayedMenu"
    class="menu"
  >
    <li class="submenu__element">
      <a [routerLink]="['/representant']"> Mon compte </a>
    </li>
    <li
      *ngFor="let patient of connectedUser.data.patients"
      class="submenu__element"
    >
      <a [routerLink]="['/patient', patient.id]">
        Compte de {{ patient.firstname }}
      </a>
    </li>
    <li class="submenu__element">
      <a [routerLink]="['/subscribe']"> Ajouter un enfant </a>
    </li>
    <li class="submenu__element">
      <button type="button" (click)="logout()">déconnexion</button>
    </li>
  </ul>

  <div
    *ngIf="connectedUser.data.role == 'patient'"
    class="disconnect"
  >
    <div class="status" (click)="logout()">
      <img src="assets/images/legal.png" />
      <span>Déconnexion</span>
    </div>
  </div>

  <p
    *ngIf="connectedUser.data.role == 'contact'"
    (click)="toogleMenu()"
    class="status"
  >
    <img src="assets/images/legal.png" />
    <span>espace contact</span>
  </p>

  <ul
    *ngIf="connectedUser.data.role == 'contact'"
    (click)="toogleMenu()"
    [class.active]="displayedMenu"
    class="menu"
  >
    <li>
      <a [routerLink]="['/contact']"> Mon compte </a>
    </li>
    <li><button type="button" (click)="logout()">déconnexion</button></li>
  </ul>
</div>
