import { Injectable } from '@angular/core'

@Injectable()

export class DateConverte {

	/*
	* Convertis une date du format AAAA-MM-JJThh:mm:ss au format JJ/MM/AAAA
	*/
	toDMY(date: any){
		const convertedDate = new Date(date)

		const year  = convertedDate.getFullYear()
		const month = ('0'+(convertedDate.getMonth()+1)).slice(-2)
		const day   = ('0'+convertedDate.getDate()).slice(-2)
		
		return day+'/'+month+'/'+year
	}
}
