import { Injectable } from '@angular/core';

import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service';

import { Router } from '@angular/router';

@Injectable()
export class InvitationTools {
  constructor(
    private requestManager: RequestManagerService,
    private router: Router
  ) {}

  public read(hash: any) {
    return this.requestManager.get('contacts/confirm/' + hash, null, true);
  }

  public displayError(hash: any) {
    this.router.navigate(['invitation', hash, 'error']);
  }

  public confirm(hash: any) {
    console.log(hash);
    this.router.navigate(['invitation', hash, 'confirm']);
  }

  public login(hash: any) {
    this.router.navigate(['login', hash]);
  }

  public signin(hash: any) {
    this.router.navigate(['invitation', hash, 'signin']);
  }

  public success(hash: any) {
    this.router.navigate(['invitation', hash, 'success']);
  }
}
