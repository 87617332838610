<div class="main">
  <div class="head">
    <div class="container">
      <p class="title">
        LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
      </p>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="wrapper">
        <div class="infos">
          <h1>Une erreur est survenu</h1>

          <p class="text">Cette invitation a déjà été validée.</p>
        </div>
      </div>
    </div>
  </div>
</div>
