import { Injectable } from '@angular/core';

import { Config } from './config';
import * as socketConfig from './socket.config';
import { UserService } from 'src/app/users/services/user-service';
import { io } from 'socket.io-client';
import { Socket } from 'ngx-socket-io';
  
@Injectable()
export class SocketService { 
  // private socket: any; 

  constructor(private user: UserService, private config: Config, private socket: Socket ) {
    
    if (
      socketConfig.socket.socket == undefined ||
      socketConfig.socket.socket == false
    ) { 
   
      
      socketConfig.socket.socket = io(
        this.config.nodeUrl[0]
      );

        
      // this.socket = socketConfig.socket.socket;
    }
     
  
       
  }

  /* 
   * Connect l'utilisateur a messenger
   */
  public connectUser() {
  
    console.log(this.socket)

    if (socketConfig.socket.userConnected == false) {
     
      const loginElements = {
        username: this.user.getUser().username,
        password: this.user.getUser().password,
      };
      this.socket.emit('user_login', loginElements, (success: any) => {
        console.log(success)

      });
      return new Promise<boolean>((resolve, reject) => {
       
        this.socket.emit('user_login', loginElements, (success: any) => {
        
          if (success) {

            socketConfig.socket.userConnected = true;
            resolve(true);
          } else reject(false);
        });
      });
    } else {
      return new Promise<boolean>((resolve, reject) => {
        resolve(true);
      });
    }
  }

  /*
   * Emeteur
   */
  public emit(route: any, data: any) {
   
    this.socket.emit(route, data);
  }

  /*
   * Ouvre une conversation et renvoi l'historique des messages
   */
  public openRoom(roomID: any) {
    return new Promise<any>((resolve, reject) => {
      this.socket.emit(
        'conversation_activation',
        { id: roomID },
        (history: any) => {
          resolve(history);
        }
      );
    });
  }

  /*
   * Ferme la conversation precedement ouverte
   */
  public closeRoom() {
    return new Promise<any>((resolve, reject) => {
      this.socket.emit('conversation_activation', null, (success: any) => {
        if (success) resolve(true);
        else reject(false);
      });
    });
  }

  /*
   * Recupere la liste de contacts de l'utilisateur
   */
  public getContactList() {
    
    return new Promise<boolean>((resolve, reject) => {
      this.socket.emit('contact_list', null, (contacts: any) => {
        console.log(contacts)
        resolve(contacts);
      });
    });
  }

  /*
   * Recupere le nombre de messages en attente
   */
  public getWaitingMessages() {
    return new Promise<number>((resolve, reject) => {
      this.socket.emit('contact_list', null, (contacts: any) => {
        let counter = 0;

        contacts.map((contact: any) => {
          counter += contact.count_not_read;
        });

        resolve(counter);
      });
    });
  }

  /*
   * Retourne le socket
   */
  public getSocket() {
    return this.socket;
  }

  /*
   * supprime les listeners
   */
  public unsetListeners() {
    socketConfig.socket._callbacks = {};
  }

  public disconnect() {
    this.socket.disconnect();
    socketConfig.socket.socket = false;
    socketConfig.socket.userConnected = false;
  }
}
