import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-password-tester',
  templateUrl: './password-tester.component.html',
  styleUrls: ['./password-tester.component.scss'],
})
export class PasswordTesterComponent implements OnInit {
  @Input() userPassword: string;
  @Input() username: string;

  @Output() passwordTested = new EventEmitter();

  public formPassword: string;
  public isLoading: Boolean = false;

  public ErrorPassword: boolean = false;
  public SuccessPassword: boolean = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  /*
   * Affiche l'erreur qui indique que le mot de passe saisi n'est pas le bon
   */
  public passwordError() {
    this.passwordTested.emit(false);
    this.ErrorPassword = true;
    this.SuccessPassword = false;
  }

  /*
   * Renvoi au parent que le mot de passe est ok
   */
  public passwordOk() {
    this.passwordTested.emit(true);
    this.SuccessPassword = true;
    this.ErrorPassword = false;
  }

  /*
   * Si le composant n'a pas reçu de mot de passe alors il test le mot de passe depuis le serveur
   */
  public testPassword() {
    const user = {
      username: this.username,
      password: this.formPassword,
    };

    this.userService.checkPassword(user).subscribe(
      (x) => {
        this.passwordOk();
      },
      (error) => {
        this.passwordError();
      }
    );
  }
}
