import { Component, OnInit, Output, EventEmitter, Input, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-subscribe-success',
  templateUrl: './subscribe-success.component.html',
  styleUrls: ['./subscribe-success.component.scss'],
})
export class SubscribeSuccessComponent implements OnInit {
  public option : string
  constructor(private route: ActivatedRoute) {
  
  }

  ngOnInit(): void {
    this.route.params
    .subscribe((value: any) => {
      
      this.option = value.option;
      console.log(this.option)
    });
  }

}