<div class="item">
  <label class="m-label">Mot de passe actuel :</label>
  <input
    [class.error]="ErrorPassword"
    class="m-input"
    type="password"
    name="password"
    required="required"
    (blur)="testPassword()"
    [(ngModel)]="formPassword"
  />
  <div *ngIf="ErrorPassword" class="m-error">
    Le mot de passe n'est pas le bon.
  </div>
</div>
