import { Injectable } from '@angular/core';

@Injectable()
export class Config {
  public apiUrl: string;
  public backUrl: string;
  public imgLinkUrl: string;
  public imgArticleUrl: string;
  public nodeUrl: Array<any>;

  private prodUrl: string = 'https://back.primakid.net';

  
  private prodNodeUrl: Array<any> = ["https://chat.primakid.net/", { path: "" }];

  constructor() {
    this.apiUrl = this.prodUrl + '/api/';
    this.backUrl = this.prodUrl + '/';
    this.imgLinkUrl = this.prodUrl + '/img/links/picture';
    this.imgArticleUrl = this.prodUrl + '/img/articles/picture';
    this.nodeUrl = this.prodNodeUrl;
  }
}
