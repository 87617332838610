<form id="formSubscribe" [formGroup]="stepForm" (submit)="onSubmit()">
  <p class="label">VÉRIFICATION DE L'IDENTITÉ</p>
  <div>
    <textarea class="m-textarea" disabled="disabled">
 Je certifie sur l'honneur l'exactitude de l'ensemble des informations saisies lors de mon inscription en tant que représentant légal ainsi que les données de(s) enfant(s) dont 
 
 je déclare être le parent ou le tuteur légal.Mon engagement sur l'honneur pourra être produit en justice si nécessaire et toute fausse déclaration de ma part m'expose à des sanctions pénales. </textarea
    >
  </div>
  <div class="validate">
    <div class="m-checkbox">
      <input type="checkbox" id="honor" formControlName="honor" />
      <span class="m-checkbox__mark"></span>
      <label for="isRepresentant">
        Je certifie être le responsable légal
        <span *ngIf="patients.length == 1">
          de
          <span class="firstname">{{ patients[0].firstname }}</span>
          <span>{{ patients[0].lastname }}</span>
        </span>

        <span *ngIf="patients.length > 1">des enfants cités ci-dessous :</span>

        <ul *ngIf="patients.length > 1">
          <li *ngFor="let patient of patients">
            -
            <span class="firstname">{{ patient.firstname }} </span>
            <span>{{ patient.lastname }}</span>
          </li>
        </ul>
      </label>
    </div>
  </div>
  <div class="submit">
    <input type="submit" class="m-button" value="Terminer" />
  </div>
</form>
