import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TokenService {
  private token = new Subject();

  /**
   * Renvoie la valeur du localStorage associée à la clé passée
   * @param {string} Nom de la clé
   * @returns {boolean|object} Valeur associée à la clé ou false si la clé n'existe pas
   */
  public get(name: any) {
    return localStorage.getItem(name);
  }

  /**
   * Persiste une donnée dans le localStorage
   * @param { string } Nom de la clé
   * @param { object|string } Données à stocker ( string / object )
   */

  public set(name: string, dataToSet: any) {
    localStorage.setItem(name, dataToSet);
  }

  /**
   * Supprime la ressource avec le nom de clé correspondant du localStorage
   * @param {string} Nom de la clé
   * @returns {boolean} True si la clée a été supprimé, False si la clée n'existe pas
   */
  public remove(name: any) {
    if (this.get(name) != null) {
      localStorage.removeItem(name);
      return true;
    }
    return false;
  }

  /**
   * Envoie un nouveau token
   * @param {string} token
   */
  sendToken(token: string) {
    this.token.next(token);
  }

  getToken(): Observable<any> {
    return this.token.asObservable();
  }
}
