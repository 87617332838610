<div class="main">
	<div class="container">
		<div class="head">
			<div class="open" 	(click)     = "displayContacts ? displayContacts = false : displayContacts = true">
				<img src="assets/icons/users-four-fill.svg" />
			</div>
			<span
				*ngIf          = "openedRoomContact"
				[class.online] = "openedRoomContact.isConnected"
				class          = "current_contact" >
				{{openedRoomContact.user.firstname}} {{openedRoomContact.user.lastname}}
			</span>
		</div>
		<div
			class="loading"
			*ngIf="roomIsLoading">
			<p>Chargement de la conversation</p>
		</div>
		<div *ngIf="!roomIsLoading" class="wrapper">
			
			<div class="sidebar" [class.displayed] = "displayContacts">
				<div
					(click) = "displayContacts ? displayContacts = false : displayContacts = true"
					class="close">
					Retourner à la conversation
				</div>
				<div class="sidebar__contacts">
					<div
					*ngFor  = 'let contact of userContacts'
					(click) = "openMessengerRoom(contact); displayContacts ? displayContacts = false : displayContacts = true"
					[class.sidebar__contact--online] = "contact.isConnected"
					[class.sidebar__contact--active] = "contact.id == openedRoom"
					[class.sidebar__contact--new]    = "roomWithNewMessage.indexOf(contact.id) > -1"
					class                                        = "sidebar__contact" >
						<img src="assets/icons/chat-circle-fill.svg" />
						<div >
							<p class="sidebar__contact__name">{{contact.user.firstname}} {{contact.user.lastname}}</p>
							<p
							class="sidebar__contact__date"
							*ngIf = "contact.messages.length > 0"
							>
								{{contact.messages[contact.messages.length -1].created | messageDate: 'lll'}}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="messages">
				<div #messenger class="messenger__messages">

					<div class="messenger__messages__header">
						<div
							(click)     = "displayContacts ? displayContacts = false : displayContacts = true"
							[class.new] = "roomWithNewMessage.length > 0"
							class       = "contact_btn" >
							
						</div>
						
					</div>
		
					<div>
						<div
						*ngFor='let message of openedRoomMessages'
						[class.messenger__messages__message--contact] = "message.user_id != userID"
						[class.messenger__messages__message--user]    = "message.user_id == userID"
						class="messenger__messages__message messenger__messages__message--contact">
						{{message.content}}
						<div class="date">
							{{message.created | messageDate: 'smart'}}
						</div>
						</div>
					</div>
					
	
					<div
						*ngIf = "contactIsTyping == true"
						class = "is_typing messenger__messages__message messenger__messages__message--contact">
						<div class="spinner">
							<div class="bounce1"></div>
							<div class="bounce2"></div>
							<div class="bounce3"></div>
						</div>
					</div>
				</div>
				<div
					*ngIf = "!roomIsLoading"
					class = "composer">
					<form (submit) = "sendMessage()">
						<textarea
							type        = "text"
							[(ngModel)] = "messageToSend"
							name        = "message"
							(keyup)     = "isTyping($event)"
							placeholder = "Votre message ici"
						></textarea>
						<button type="submit">
							<span>Envoyer le message</span>
							<img src="assets/icons/arrow-square-right-fill.svg" />
						</button>
						
						
					</form>
					<div class="composer__emoji">
						<div
							(click)="addEmoji(':)')"
							class="emoji">🙂
						</div>
						<div
							(click)="addEmoji(';)')"
							class="emoji">😉
						</div>
						<div
							(click)="addEmoji(':D')"
							class="emoji">😀
						</div>
						<div
							(click)="addEmoji(':\')')"
							class="emoji">😂
						</div>
						<div
							(click)="addEmoji('<3')"
							class="emoji">😍
						</div>
						<div
							(click)="addEmoji(':p')"
							class="emoji">😜
						</div>
						<div
							(click)="addEmoji(':(')"
							class="emoji">😟
						</div>
						<div
							(click)="addEmoji(':o')"
							class="emoji">😮
						</div>
						<div
							(click)="addEmoji(':\'(')"
							class="emoji">😢
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>