<div class="main">
  <div class="head">
    <div class="container">
      <p class="title">
        LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
      </p>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="wrapper">
        <div class="infos">
          <img src="assets/images/representant--character.png" />
          <div>
            <h1><span>Bienvenue sur votre</span> Gestionnaire de compte !</h1>
          </div>
        </div>
      </div>
      <div class="form-content">
        <div class="sidebar">
          <div class="sidebarBox">
            <img
              src="assets/images/informations.png"
              alt="informations sur le tableau de bord"
            />
            <p><span>Votre tableau de bord</span>vous permet de :</p>
            <ul>
              <li>Compléter / Modifier vos informations personnelles</li>
              <li>Changer votre mot de passe</li>
              <li>Suspendre ou supprimer votre compte</li>
            </ul>
          </div>
        </div>
        <div class="form">
          <div class="tabs">
            <div
              [ngClass]="displayedTab == 'informations' ? 'active' : ''"
              class="tabs__tab informations"
              (click)="display('informations')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
              </svg>
              <span>Informations personnelles</span>
            </div>

            <div
              [ngClass]="displayedTab == 'password' ? 'active' : ''"
              class="tabs__tab password"
              (click)="display('password')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
              <span>Mot de passe</span>
            </div>

            <div
              [ngClass]="displayedTab == 'status' ? 'active' : ''"
              class="tabs__tab configuration"
              (click)="display('status')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span>Gestion de compte</span>
            </div>
          </div>

          <div class="formForm">
            <div class="stepsWrapper">
              <app-representant-informations
                [hidden]="displayedTab != 'informations'"
              ></app-representant-informations>
              <app-representant-password
                [hidden]="displayedTab != 'password'"
              ></app-representant-password>
              <app-representant-status
                [hidden]="displayedTab != 'status'"
              ></app-representant-status>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
