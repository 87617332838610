import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { FacilitiesService } from '../../services/facilities-service';

@Component({
  selector: 'app-facilities-list',
  templateUrl: './facilities-list.component.html',
  styleUrls: ['./facilities-list.component.css'],
})
export class FacilitiesListComponent implements OnInit {
  @Input() etablishmentIdData: number;
  @Input() serviceID: number;
  @Input() errorEtablisment: any = false;
  @Input() errorService: any = false;
  @Output() serviceSelected = new EventEmitter();
  @Output() etablismentSelected = new EventEmitter();

  public establishmentID: number;
  public establishments: Array<any> = [];
  private services: Array<any> = [];
  public selectableServices: Array<any> = [];
  public isLoading: boolean = false;
  private entityLoaded: number = 0;

  constructor(private facilitiesService: FacilitiesService) {}

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.errorEtablisment);
    console.log(this.errorService);
   
  }
  ngOnInit() {
    this.isLoading = true;
    console.log(this.serviceID)
    console.log(this.establishmentID)
    this.facilitiesService
      .getEstablishments()
      .subscribe((establishments: any) => {
        this.establishments = establishments.data;
        this.entityLoaded++;
        this.isLoading = false;
      });

    this.facilitiesService.getServices().subscribe((services: any) => {
      if (services && services.data) {
        this.services = services.data;
        if (this.etablishmentIdData) {
          this.establishmentID = this.etablishmentIdData;
          this.getEtablishementByID(this.establishmentID);
        }
      }
    });
  }

  getEtablishementByID(id: any) {
    this.getServicesByEtablishement(this.establishmentID);
    this.etablismentSelected.emit(id);
  }

  getEtablishement(event: any) {
    this.establishmentID = event.target.value;
    this.getServicesByEtablishement(this.establishmentID);
    this.etablismentSelected.emit(event.target.value);
  }

  getServicesByEtablishement(establishmentID: number) {
    console.log(this.services);
    this.selectableServices = this.services.filter(
      (service) => service.establishment_id == establishmentID
    );
  }

  getSelectedValue(event: any) {
    this.serviceSelected.emit(event.target.value);
  }
}
