<form [formGroup]="informationForm" (submit)="onSubmit()">
  <div class="item">
    <label class="m-label">Nom</label>
    <input
      [class.error]="
        informationForm.get('lastname')?.hasError('required') && submitted
      "
      class="m-input"
      type="text"
      formControlName="lastname"
    />
    <div
      *ngIf="informationForm.get('lastname')?.hasError('required') && submitted"
      class="m-error"
    >
      Le nom est requis
    </div>
  </div>
  <div class="item">
    <label class="m-label">Prénom</label>
    <input
      [class.error]="
        informationForm.get('firstname')?.hasError('required') && submitted
      "
      class="m-input"
      type="text"
      formControlName="firstname"
    />
    <div
      *ngIf="
        informationForm.get('firstname')?.hasError('required') && submitted
      "
      class="m-error"
    >
      Le prénom est requis
    </div>
  </div>
  <div class="item">
    <username-predict
      (usernameSend)="usernameSend($event)"
      [lastname]="informationForm.value.lastname"
      [firstname]="informationForm.value.firstname"
      [username]="informationForm.value.username"
    ></username-predict>
  </div>
  <div class="item">
    <label class="m-label">Email</label>
    <input
      [class.error]="
        (informationForm.get('email')?.hasError('required') || emailExist) &&
        submitted
      "
      class="m-input"
      type="text"
      formControlName="email"
      (change)="changeEmail($event)"
    />
    <p *ngIf="emailExist" class="m-error">Cet email est deja utilisé</p>
    <div
      *ngIf="informationForm.get('email')?.hasError('required') && submitted"
      class="m-error"
    >
      L'email est requis
    </div>
  </div>
  <div class="item">
    <label class="m-label">NÉ(E) LE : JJ/MM/AAAA</label>
    <input
      [class.error]="
        informationForm.get('birthday')?.hasError('required') && submitted
      "
      class="m-input"
      type="date"
      formControlName="birthday"
    />

    <div
      *ngIf="informationForm.get('birthday')?.hasError('required') && submitted"
      class="m-error"
    >
      La date de naissance est requis
    </div>
  </div>
  <div class="item">
    <label class="m-label">Adresse</label>
    <input
      [class.error]="
        informationForm.get('address')?.hasError('required') && submitted
      "
      class="m-input"
      type="text"
      formControlName="address"
    />
    <div
      *ngIf="informationForm.get('address')?.hasError('required') && submitted"
      class="m-error"
    >
      L'adresse est requis
    </div>
  </div>
  <div class="item">
    <label class="m-label">Code postal</label>
    <input
      [class.error]="
        informationForm.get('zip')?.hasError('required') && submitted
      "
      class="m-input"
      type="text"
      formControlName="zip"
    />
    <div
      *ngIf="informationForm.get('zip')?.hasError('required') && submitted"
      class="m-error"
    >
      Le code postal est requis
    </div>
  </div>
  <div class="item">
    <label class="m-label">Ville</label>
    <input
      [class.error]="
        informationForm.get('city')?.hasError('required') && submitted
      "
      class="m-input"
      type="text"
      formControlName="city"
    />
    <div
      *ngIf="informationForm.get('city')?.hasError('required') && submitted"
      class="m-error"
    >
      La ville est requis
    </div>
  </div>
  <div class="item">
    <label class="m-label">Téléphone</label>
    <input
      [class.error]="
        informationForm.get('phone')?.hasError('required') && submitted
      "
      class="m-input"
      type="text"
      formControlName="phone"
    />
    <div
    
      *ngIf="informationForm.get('phone')?.hasError('required') && submitted"
      class="m-error"
    >
      Le numéro de téléphone est requis
    </div>
    <div
    *ngIf="informationForm.get('phone')?.hasError('telephoneInvalide') && submitted"
    class="m-error"
  >
    Le numéro de téléphone n'est pas correct
  </div>
  </div>
  <input [disabled]="emailExist" type="submit" class="m-button" [value]="loading ? 'Enregistrement en cours' : 'Enregistrer'" />
 
  <div  *ngIf="errorMessage || emailExist" class="m-error-bloc">
    <img src="assets/icons/info-fill.svg" />
    Veuillez vérifier vos informations.
  </div>
  <app-message [message]="validationMessage"> </app-message>
</form>
