<div class="item">
  <label class="m-label">Établissement</label>

  <select
    class="m-select"
    name="establishment"
    [disabled]="isLoading"
    required="required"
    [(ngModel)]="establishmentID"
    (change)="getEtablishement($event)"
  >
    <option value="0" disabled>
      Établisement<span *ngIf="establishments.length > 1">s</span>
    </option>
    <option
      *ngFor="let establishment of establishments"
      value="{{ establishment.id }}"
      [selected]= "establishmentID == establishment.id"
    >
      {{ establishment.name }}
    </option>
  </select>
  <p *ngIf="errorEtablisment" class="m-error">Une établissement est requis</p>
</div>
<div class="item">
  <label class="m-label">Service</label>

  <select
    class="m-select"
    name="patient_service"
    [(ngModel)]="serviceID"
    [disabled]="isLoading || !establishmentID"
    required="required"
    (change)="getSelectedValue($event)"
  >
    <option value="0" disabled>
      Service<span *ngIf="selectableServices.length > 1">s</span>
    </option>
    <option  [selected]= "serviceID == service.id" *ngFor="let service of selectableServices" value="{{ service.id }}">
      {{ service.name }}
    </option>
  </select>
  <p *ngIf="errorService" class="m-error">Un service est requis</p>
</div>
