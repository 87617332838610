<form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
  <div>
    <p class="label">INFORMATIONS RESPONSABLE LÉGAL</p>
    <div class="item">
      <label class="m-label">Nom</label>
      <input
        [class.error]="
          stepForm.get('lastname')?.hasError('required') && submitted
        "
        class="m-input"
        type="text"
        formControlName="lastname"
      />
      <div
        *ngIf="stepForm.get('lastname')?.hasError('required') && submitted"
        class="m-error"
      >
        Le nom est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">Prénom</label>
      <input
        [class.error]="
          stepForm.get('firstname')?.hasError('required') && submitted
        "
        class="m-input"
        type="text"
        formControlName="firstname"
      />
      <div
        *ngIf="stepForm.get('firstname')?.hasError('required') && submitted"
        class="m-error"
      >
        Le prénom est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">Email</label>
      <input
        [class.error]="
          (stepForm.get('email')?.hasError('required') || emailExist) &&
          submitted
        "
        class="m-input"
        type="text"
        formControlName="email"
        (change)="changeEmail($event)"
      />
      <p *ngIf="emailExist" class="m-error">Cet email est deja utilisé</p>
      <div
        *ngIf="stepForm.get('email')?.hasError('required') && submitted"
        class="m-error"
      >
        L'email est requis
      </div>
    </div>
  </div>
  <div class="infos">
    <p class="label">ACCÈS</p>
    <p class="text">Utilisez ces codes d’accès pour :</p>
    <ul>
      <li>Dialoguer avec votre enfant</li>
      <li>
        Configurer le service de Tchat de votre enfant (autoriser et restreindre
        les accès des personnes avec lesquelles il pourra discuter)
      </li>
      <li>
        Gérer vos information personnelles depuis votre espace d’administration
      </li>
    </ul>
    <div>
      <div class="item">
        <username-predict
          (usernameSend)="usernameSend($event)"
          [lastname]="stepForm.value.lastname"
          [firstname]="stepForm.value.firstname"
          [username]="stepForm.value.username"
        ></username-predict>
      </div>

      <div class="item">
        <label class="m-label">Nouveau mot de passe</label>
        <input formControlName="password" class="m-input" type="text" />
        <div
          *ngIf="stepForm.get('password')?.hasError('required') && submitted"
          class="m-error"
        >
          Une mot de passe est requis
        </div>
      </div>
      <div class="item">
        <label class="m-label">Confirmez</label>
        <input formControlName="confirmPassword" class="m-input" type="text" />
        <div *ngIf="passwordConfirmed" class="m-error">
          Le mot de passe n'est pas le meme
        </div>
      </div>
      <input type="submit" class="m-button" value="Suivant" />
    </div>
  </div>
</form>
