<form id="formSubscribe" [formGroup]="stepForm" (ngSubmit)="onSubmit()" class="form">
  <div>
    <p class="label">INFORMATIONS RESPONSABLE LÉGAL</p>
    <div class="item">
      <label class="m-label">Nom</label>
      <input
        [class.error]="
          stepForm.get('lastname')?.hasError('required') && submitted
        "
        class="m-input"
        type="text"
        formControlName="lastname"
      />
      <div
        *ngIf="stepForm.get('lastname')?.hasError('required') && submitted"
        class="m-error"
      >
        Le nom est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">Prénom</label>
      <input
        [class.error]="
          stepForm.get('firstname')?.hasError('required') && submitted
        "
        class="m-input"
        type="text"
        formControlName="firstname"
      />
      <div
        *ngIf="stepForm.get('firstname')?.hasError('required') && submitted"
        class="m-error"
      >
        Le prénom est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">NÉ(E) LE : JJ/MM/AAAA</label>
      <input
        [class.error]="
          stepForm.get('birthday')?.hasError('required') && submitted
        "
        class="m-input"
        type="date"
        formControlName="birthday"
      />
      <div
        *ngIf="stepForm.get('birthday')?.hasError('required') && submitted"
        class="m-error"
      >
        La date de naissance est requis
      </div>
    </div>
    <a [routerLink]="['/login']" class="account">J'ai déja un compte</a>
    <div class="item">
      <label class="m-label">Email</label>
      <input
        [class.error]="
          (stepForm.get('email')?.hasError('required') || emailExist) &&
          submitted
        "
        class="m-input"
        type="text"
        formControlName="email"
        (change)="changeEmail($event)"
      />
      <p *ngIf="emailExist" class="m-error">Cet email est deja utilisé</p>
      <div
        *ngIf="stepForm.get('email')?.hasError('required') && submitted"
        class="m-error"
      >
        L'email est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">Adresse</label>
      <input
        [class.error]="
          stepForm.get('address')?.hasError('required') && submitted
        "
        class="m-input"
        type="text"
        formControlName="address"
      />
      <div
        *ngIf="stepForm.get('address')?.hasError('required') && submitted"
        class="m-error"
      >
        L'adresse est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">Code postal</label>
      <input
        [class.error]="stepForm.get('zip')?.hasError('required') && submitted"
        class="m-input"
        type="text"
        formControlName="zip"
      />
      <div
        *ngIf="stepForm.get('zip')?.hasError('required') && submitted"
        class="m-error"
      >
        Le code postal est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">Ville</label>
      <input
        [class.error]="stepForm.get('city')?.hasError('required') && submitted"
        class="m-input"
        type="text"
        formControlName="city"
      />
      <div
        *ngIf="stepForm.get('city')?.hasError('required') && submitted"
        class="m-error"
      >
        La ville est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">Téléphone</label>
      <input
        [class.error]="(stepForm.get('phone')?.hasError('required') || stepForm.get('phone')?.hasError('telephoneInvalide')) && submitted"
        class="m-input"
        type="text"
        formControlName="phone"
      />
      <div
        *ngIf="stepForm.get('phone')?.hasError('required') && submitted"
        class="m-error"
      >
        Le numéro de téléphone est requis
      </div>
      <div
        *ngIf="stepForm.get('phone')?.hasError('telephoneInvalide') && submitted"
        class="m-error"
      >
        Le numéro de téléphone n'est pas valide
      </div>
    </div>
  </div>
  <div class="infos">
    <p class="label">ACCÈS DU REPRÉSENTANT LÉGAL</p>
    <p class="text">Utilisez ces codes d’accès pour :</p>
    <ul>
      <li>Dialoguer avec votre enfant</li>
      <li>
        Configurer le service de Tchat de votre enfant (autoriser et restreindre
        les accès des personnes avec lesquelles il pourra discuter)
      </li>
      <li>
        Gérer vos information personnelles depuis votre espace d’administration
      </li>
    </ul>
    <div>
      <div class="item">
        <username-predict
          (usernameSend)="usernameSend($event)"
          [lastname]="stepForm.value.lastname"
          [firstname]="stepForm.value.firstname"
          [username]="stepForm.value.username"
        ></username-predict>
      </div>
      <div class="item">
        <label class="m-label">Nouveau mot de passe</label>
        <input formControlName="password" class="m-input" type="password" />
        <div
          *ngIf="stepForm.get('password')?.hasError('required') && submitted"
          class="m-error"
        >
          Une mot de passe est requis
        </div>
      </div>
      <div class="item">
        <label class="m-label">Confirmez</label>
        <input formControlName="confirmPassword" class="m-input" type="password" />
        <div *ngIf="passworConfirmed" class="m-error">
          Le mot de passe n'est pas le meme
        </div>
      </div>
      <input type="submit" class="m-button" value="Suivant" />
      <p *ngIf="errorForm" class="m-error-bloc">
        Il y a une erreur sur le formulaire, veuillez vérifier vos données
      </p>
    </div>
  </div>
</form>
