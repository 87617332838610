<div class="main">
  <div class="head">
    <div class="container">
      <p class="title">
        LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
      </p>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="wrapper">
        <div class="infos">
          <img src="assets/images/login--character.png" />
          <div>
            <h1>
              Bienvenue<br />sur
              <span>l'espace d'inscription «Correspondant»</span> de PRIMAKID !
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form">
    <div class="container">
      <div class="formWrapper">
        <div class="formInfos">
          <img src="assets/images/informations.png" />
          <p class="formInfosTitle">Pourquoi vous inscrire ?</p>
          <ul>
            <li>
              En vous créant un compte «Correspondant», vous pourrez communiquer
              en temps réel avec le jeune hospitalisé sur la messagerie
              instantannée de Primakid.
            </li>
            <li>
              Pour un enfant ou adolescent hospitalisé, la communication avec
              son entourage est essentielle.
            </li>
          </ul>
          <p class="subtext">Merci pour votre action !</p>
        </div>
        <div class="formForm">
          <app-invitation-signin-buttons
            *ngIf="display == 'buttons'"
            class="contact__signin contact__signin__buttons"
            (cliquedButton)="action($event)"
          >
          </app-invitation-signin-buttons>

          <app-invitation-signin-form
            *ngIf="display == 'form'"
            [invitationEmail]="decodedInvitation.email"
            (isSend)="createContactAccount($event)"
          ></app-invitation-signin-form>
        </div>
      </div>
    </div>
  </div>
</div>
