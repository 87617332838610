<div class="main">
    <div class="head">
      <div class="container">
        <p class="title">
          LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
        </p>
      </div>
    </div>
    <div class="content">
        <div class="container">
            <div class="wrapper">
                <div>
                    <img src="assets/images/subscribe-success--heading.png" />
                </div>
                <div class="infos">

                   
                  
                    <h1 *ngIf="option != 'patient-added'"><span>Félicitation !</span>
                        Votre accès à Primakid
                        <span>est accepté !</span></h1>

                    <h1 *ngIf="option == 'patient-added'"><span>Félicitation !</span>

                        Vote enfant

                        <span>a été ajouté à Primakid !</span></h1>
                    <div class="buttons">
                        <a [routerLink]="['/']">
                            <div 
                                class="m-button m-button--border-blue">
                                Accéder à primakid
                            </div>
                        </a>
            
                        <a [routerLink]="['/representant']">
                            <div 
                            class="m-button m-button--border-blue">
                            Accéder à votre espace d'administration
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>