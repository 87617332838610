<div class="item">
  <label class="m-label">Nouveau mot de passe :</label>
  <input
    class="m-input"
    [class.error]="errorPasswordSame || errorPasswordFormat"
    type="password"
    name="newPassword"
    required="required"
    (blur)="testPassword()"
    [(ngModel)]="password.password"
  />
</div>

<div class="item">
  <label class="m-label">Confirmez :</label>
  <input
    class="m-input"
    [class.error]="errorPasswordSame || errorPasswordFormat"
    type="password"
    name="confirmPassword"
    required="required"
    (blur)="testPassword()"
    [(ngModel)]="password.confirm"
  />

  <p *ngIf="errorPasswordFormat" class="m-error">
    Le mot de passe doit contenir au moins 4 caractères.
  </p>

  <p *ngIf="errorPasswordSame" class="m-error">
    Les mots de passe doivent être identiques
  </p>
</div>
