import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationTools } from '../../services/invitation-service';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private submitButton: any;
  private form: any;
  loginForm: FormGroup;

  public invitationHash: string;
  public invitationInfos: any;

  public submitted: boolean = false;
  public errorMessage: any = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private invitation: InvitationTools,
    private user: UserService
  ) {
    if (user.getUser()) this.router.navigate(['/']);
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    const params = this.route.snapshot.paramMap.get('hash');

    if (params) {
      this.invitationHash = params;
      this.readHash();
    }
  }

  /*
   * Si le login est appelé avec un parametre 'hash' ( acceptation d'un contact )
   */
  public readHash() {
    this.invitation
      .read(this.invitationHash)
      .subscribe((decodedInvitation: any) => {
        this.invitationInfos = decodedInvitation;
      });
  }

  /*
   * Test si le compte loggé est desactive ou non
   * Return boolean (true / false)
   */
  private accountIsDisabled(account: any) {
    if (account.status == 'disabled') return true;
    else return false;
  }

  /*
   * Redirige l'utilisateur en fonction de son profile
   */
  private redirectUser() {
    if (typeof this.invitationHash != 'undefined')
      this.router.navigate(['invitation', this.invitationHash, 'confirm']);
    else this.router.navigate(['./']);
  }

  /*
   * Redirige l'utilisateur vers la pazge qui l'informe que son compte est désactivé
   */
  private redirectToDisabledAccountPage(role: any) {
    console.log('redirect')
    if (role == 'representant') {
      const userToken = window.btoa(
        this.loginForm.value.username + ':' + this.loginForm.value.password
      );
      this.router.navigate(['./disabled-account', userToken]);
    } else this.router.navigate(['./disabled-patient-account']);
  }

  public onSubmit() {
    this.submitted = true;
    this.errorMessage = false;
    if (this.loginForm.valid) {
      this.user.Login(this.loginForm.value).subscribe(
        (serverResponse: any) => {
          const account = serverResponse.data;
          
          if (account.role == 'representant' || account.role == 'contact')
            account.slice = 2;

          if (
            account.role == 'admin' ||
            account.role == 'super' ||
            account.role == 'contributor'
          ) {
            // window.location.replace(
            //   this.config.backUrl +
            //     'admin/users/login?_method=POST&username=sylvain.saint-andre&password=password'
            // );
            return;
          }
          if (this.accountIsDisabled(account)) {
            this.submitted = false;
            this.redirectToDisabledAccountPage(account.role);
          }
         
            
          else {
            this.user.setUser(
              this.loginForm.value.username,
              this.loginForm.value.password,
              account
            );
            this.submitted = false;
            this.redirectUser();
          }
        },
        (error) => {
          this.errorMessage = true;
          this.submitted = false;
        }
      );
    }
  }
}
