import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service';
import { UserService } from '../../services/user-service';
import * as moment from 'moment';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
  public steps = [1, 2, 3];

  public displayedStep: number = 1;
  public userPatient: any;
  public subscribe: any = {
    certified: false,
    representant: {
      id:'',
      lastname : '',
      firstname : '',
      birthday : '',
      email : '',
      address : '',
      zip : '',
      city : '',
      phone : '',
      password : '',
      confirmPassword : '',
      username: '',
    },
    patients: [
        {
      
        username:  '',
        lastname: '',
        firstname:'' ,
        birthday: '',
        slice: '1',
        role: 'patient',
        etablishment: '',
        service_id: '',
        relation: '',
        password: '',
        confirmPassword: ''
      }
    ],
  };

  

  public addPatientMode = false;

  constructor(
    private requestService: RequestManagerService,
    private router: Router,
    private user: UserService
  ) {
   
  }

  ngOnInit() {
    if (this.user.getUser()) {
      this.displayedStep = 2;
      this.displayStep(2);
      this.addPatientMode = true;
      this.userPatient = this.user.getUser();
   
    
      this.subscribe.representant = {
        username: this.userPatient.username,
        id:this.userPatient.data.id,
        lastname : this.userPatient.data.lastname,
        firstname : this.userPatient.data.firstname,
        birthday : this.userPatient.data.birthday,
        email : this.userPatient.data.email,
        address : this.userPatient.data.address,
        zip : this.userPatient.data.zip,
        city : this.userPatient.data.city,
        phone : this.userPatient.data.phone,
        role : this.userPatient.data.role,
        password : this.userPatient.password,
      }
     
    }
  }

  public displayStep(step: number) {
    if (step <= this.displayedStep) {
      this.displayedStep = step;
    } 
  }

  public changeStep(step: number) {

    this.displayedStep = step;
  }

  getRepresentantValues(representant: any) {
    this.subscribe.representant = representant;
    this.displayedStep = 2;
    this.displayStep(2);
  }

  getPatientsValues(patient: any) {
    this.subscribe.patients[0] = patient;
    this.displayedStep = 3;
    this.displayStep(3);
   
  }

  
  private redirectUser(){
    if(this.addPatientMode)
       this.router.navigate(['subscribe/success', 'patient-added'])
    else
       this.router.navigate(['subscribe/success'])

 }


  getHonor(honor: any) {
    this.subscribe.certified = true;

    let finalData: any = {
      firstname:  this.subscribe.representant.firstname,
      lastname: this.subscribe.representant.lastname,
      phone: this.subscribe.representant.phone,
      zip: this.subscribe.representant.zip,
      birthday: moment(this.subscribe.representant.birthday).format('DD/MM/YYYY'),
      address: this.subscribe.representant.address,
      honor: (this.subscribe.certified) ? 1 : 0,
      role: "representant",
      email: this.subscribe.representant.email,
      city: this.subscribe.representant.city,
      password: this.subscribe.representant.password, 
      patients: [
        {
          firstname: this.subscribe.patients[0].firstname,
          lastname: this.subscribe.patients[0].lastname,
          role: "patient",
          slice: this.subscribe.patients[0].slice,
          birthday: moment(this.subscribe.patients[0].birthday).format('DD/MM/YYYY'),
          service_id: this.subscribe.patients[0].service_id,
          relation: this.subscribe.patients[0].relation,
          password: this.subscribe.patients[0].password
        }
      ]
    }
    if (this.userPatient) {
     
      finalData.id = this.userPatient.data.id,
      finalData.firstname =  this.userPatient.data.firstname,
      finalData.lastname= this.userPatient.data.lastname,
      finalData.phone = this.userPatient.data.phone,
      finalData.zip = this.userPatient.data.zip,
      finalData.birthday =  moment(this.userPatient.data.birthday).format('DD/MM/YYYY'),
      finalData.address = this.userPatient.data.address,
      finalData.email = this.userPatient.data.email,
      finalData.city = this.userPatient.data.city,
     
      delete finalData.password;
    }
  
    if (this.userPatient) {
     
      this.user.updateUser(this.userPatient.data.id, finalData).subscribe((response: any) => {
        const loginValue = {
          username: this.subscribe.representant.username,
          password:  this.subscribe.representant.password,
        }
        if(response) {
          this.user.Login(loginValue).subscribe(
            (serverResponse: any) => {
              const account = serverResponse.data;
              this.user.setUser(
                loginValue.username,
                loginValue.password,
                account
              );
              this.router.navigate(['subscribe-success', 'patient-added'])
  
            },
            (error) => {
             
            }
          );
        }
        
      
        this.redirectUser();
      });
    } else {

      this.user.SubscribeUsers(finalData).subscribe((response: any) => {
      
        const loginValue = {
          username: this.subscribe.representant.username,
          password:  this.subscribe.representant.password,
        }
    
        if(response) {
          this.user.Login(loginValue).subscribe(
            (serverResponse: any) => {
              const account = serverResponse.data;
              this.user.setUser(
                loginValue.username,
                loginValue.password,
                account
              );
              this.router.navigate(['subscribe-success'])
  
            },
            (error) => {
              console.log(error)
            }
          );
        }
        
      });
    }
 
  }
}
