<div class="main">
	<div class="head">
	  <div class="container">
		<p class="title">
		  LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
		</p>
	  </div>
	</div>
	<div class="content" *ngIf = "userToken">
	  <div class="container">
		<div class="wrapper">
		  <div class="infos">
			<h1>Votre compte a été désactivé</h1>
  
			<p class="text">
				Votre compte a été désactivé, pour pouvoir vous reconnecter, merci de bien vouloir le réactiver en cliquant sur le bouton ci-dessous.
			</p>
			<button 
			(click)= "activateAccount()"
			[disabled] = "button.disable"
			class      = "m-button">
			{{button.text}}
				</button>
		  </div>
		</div>
	  </div>
	</div>
	<div class="content" *ngIf = "!userToken">
		<div class="container">
		  <div class="wrapper">
			<div class="infos">
			  <h1>Votre compte a été désactivé</h1>
	
			  <p class="text">
				Ton compte a été désactivé. Pour utiliser PRIMAKID demande à tes parents ou à ton representant légal de le réactiver.
			  </p>
			</div>
		  </div>
		</div>
	  </div>
  </div>
  

