<div class="main">
  
  <div class="menu">
    <div class="container">
      <div class="menuWrapper">
        <p class="hello">Bonjour
          <span *ngIf="userInfos"> {{userInfos.data.firstname}}</span>
          !

        </p>
        <div class="slice">
          <ul>
            <li
              [class.active]="contentFilters.slice == 1"
              (click)="getSelectedSlice(1)"
            >
              <img src="assets/images/slice-selector--mini.png" />
            </li>
            <li
              [class.active]="contentFilters.slice == 2"
              (click)="getSelectedSlice(2)"
            >
              <img src="assets/images/slice-selector--maxi.png" />
            </li>
            <li
              *ngIf="contentFilters.slice == 3 && userInfos.data.role == 'patient'"
              [class.active]="contentFilters.slice == 3"
              (click)="getSelectedSlice(3)"
            >
              <img src="assets/images/slice-selector--ado.png" />
            </li>
            <li
              *ngIf="!userInfos"
             
              class="ado"
            >
              <img src="assets/images/slice-selector--ado.png" />
              <div class="toolbar">
                Cet onglet est réservé aux jeunes disposant d’un compte PrimADO
              </div>
            </li>

           

          </ul>
  
        </div>
       
        <p class="account messenger">
          <a
            *ngIf="userCanAccessMessenger && !loadingUser"
            [routerLink]="['/messenger']"
            class="header__bottom__messenger">
            <span class="hex"></span>
            <span class="messenger-icon"><img src="assets/icons/chats-circle-fill.svg" /></span>
            <div *ngIf="this.waitingMessages > 0" class="counter">
              {{this.waitingMessages > 9 ? '9+' : this.waitingMessages}}
            </div>
          </a>
          <a   [routerLink]="['./login']" *ngIf="!userCanAccessMessenger && !loadingUser" class="m-button">
            <img src="assets/images/legal.png" />Mon espace</a
          >
        </p>
      </div>
    </div>
  </div>

  <div id="content" class="links__selector">
    <div class="container">
      <ul class="links__categories">
        <li
          (click)="applyCategoryFilter('games')"
          [class.active]="contentFilters.category == 'games'"
          class="category category--game"
        >
          <div></div>
          <span>Jeux</span>
        </li>
        <li
          (click)="applyCategoryFilter('clips')"
          [class.active]="contentFilters.category == 'clips'"
          class="category category--video"
        >
          <div></div>
          <span>Video Musique</span>
        </li>

        <li
          (click)="applyCategoryFilter('drawings')"
          [class.active]="contentFilters.category == 'drawings'"
          class="category category--drawing"
        >
          <div></div>
          <span>Dessin</span>
        </li>

        <li
          (click)="applyCategoryFilter('learn')"
          [class.active]="contentFilters.category == 'learn'"
          class="category category--learning"
        >
          <div></div>
          <span>Apprendre</span>
        </li>
        <!--
                 -->
        <li
          (click)="applyCategoryFilter('news')"
          [class.active]="contentFilters.category == 'news'"
          class="category category--news"
        >
          <div></div>
          <span>Actus</span>
        </li>
      </ul>
      <div class="mobile-select">
        <select
          class="m-button m-button--border-blue"
          (change)="applyCategoryFilterEvent($event)"
        >
          <option value="all">Toutes catégories</option>
          <option value="games">Jeux</option>
          <option value="clips">Video Musique</option>
          <option value="drawings">Dessin</option>
          <option value="learn">Apprendre</option>
          <option value="news">Actus</option>
        </select>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="m-loading">
    <p>Chargement du contenu...</p>
  </div>

  <div *ngIf="!loading" class="links__wrapper">
    <div class="container">
      <div [class.active]="!loading" class="link__thumbnails">
        <div *ngFor="let link of links" class="link__thumbnails__item">
          <div (click)="openLink(link)" class="thumbnail">
            <img [src]="getLinkImageSrc(link)" />

            <div class="thumbnail__infos">
              <p class="thumbnail__title">
                <span>{{ link.name }}</span>
                <small> / {{ link.category_text || link.type }}</small>
              </p>
              <p>{{ link.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="paginate">
    <div class="container">
      <ul *ngIf="pagination && !loading">
        <li
          *ngFor="
            let page of createPagination(pagination.pageCount);
            let currentIndex = index
          "
          (click)="goPage(currentIndex + 1)"
        >
          <span
            class="hex"
            [class.active]="contentFilters.page == currentIndex + 1"
          ></span>
          <span class="number">{{ currentIndex + 1 }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
