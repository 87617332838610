import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class Step3Component implements OnInit {
  @Input() addPatientMode: any;
  @Input() patients: any;
  @Output() changeStep = new EventEmitter();
  stepForm: FormGroup;

  public submitted: boolean = false;

  constructor(
    private elRef: ElementRef
  ) {
    this.stepForm = new FormGroup({
      honor: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  ngOnInit(): void {
    const element = this.elRef.nativeElement.querySelector('#formSubscribe');
 
    window.scrollTo({top: element.offsetTop - 170, behavior: 'smooth'});
  }

  onSubmit() {
    this.submitted = true;

    if (this.stepForm.valid) {
      this.changeStep.emit(this.stepForm.value);
    }
  }
}
