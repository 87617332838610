<form id="formSubscribe" [formGroup]="stepForm" (ngSubmit)="onSubmit()">
  <div>
    <p class="label">INFORMATIONS JEUNE PATIENT</p>
    <div class="item">
      <label class="m-label">Nom</label>
      <input
        [class.error]="
          stepForm.get('lastname')?.hasError('required') && submitted
        "
        class="m-input"
        type="text"
        formControlName="lastname"
      />
      <p
        *ngIf="stepForm.get('lastname')?.hasError('required') && submitted"
        class="m-error"
      >
        Le nom est requis
      </p>
    </div>
    <div class="item">
      <label class="m-label">Prénom</label>
      <input
        [class.error]="
          stepForm.get('firstname')?.hasError('required') && submitted
        "
        class="m-input"
        type="text"
        formControlName="firstname"
      />
      <p
        *ngIf="stepForm.get('firstname')?.hasError('required') && submitted"
        class="m-error"
      >
        Le nom est requis
      </p>
    </div>
    <div class="item">
      <label class="m-label">NÉ(E) LE : JJ/MM/AAAA</label>
      <input
        [class.error]="
          stepForm.get('birthday')?.hasError('required') && submitted
        "
        class="m-input"
        type="date"
        formControlName="birthday"
      />
      <p
        *ngIf="stepForm.get('birthday')?.hasError('required') && submitted"
        class="m-error"
      >
        La date de naissance est requis
      </p>
    </div>

    <app-facilities-list
      [etablishmentIdData] = "patient[0].etablishment"
      [serviceID] = "patient[0].service_id"
      [errorEtablisment]="
        stepForm.get('etablishment')?.hasError('required') && submitted
      "
      [errorService]="
        stepForm.get('service_id')?.hasError('required') && submitted
      "
      (serviceSelected)="serviceSelected($event)"
      (etablismentSelected)="etablismentSelected($event)"
    ></app-facilities-list>

    <div class="item">
      <label class="m-label">Lien avec le jeune</label>
      <select
    
        [class.error]="
          stepForm.get('relation')?.hasError('required') && submitted
        "
        class="m-select"
        formControlName="relation"
      >
        <option *ngFor="let relation of relations" [value]="relation.id">
          {{ relation.name }}
        </option>
      </select>
      <p
        *ngIf="stepForm.get('relation')?.hasError('required') && submitted"
        class="m-error"
      >
        Une relation est requis
      </p>
    </div>
  </div>
  <div class="infos">
    <p class="label">CHOIX DE L’OFFRE PRIMAKID</p>
    <p class="text">
      PRIMAKID propose des services et des contenus médias différents selon
      l’âge de votre enfant. Choisissez l’offre adaptée à son âge !
    </p>
    <div class="slices">
      <div class="slices__item">
        <input
          [checked]="stepForm.value.slice === '1'"
          type="radio"
          name="slice"
          formControlName="slice"
          [value]="'1'"
        />
        <div class="slices__slice slices__slice--minikid"></div>
      </div>
      <div class="slices__item">
        <input
          [checked]="stepForm.value.slice === '2'"
          type="radio"
          name="slice"
          formControlName="slice"
          [value]="'2'"
        />
        <div class="slices__slice slices__slice--maxikid"></div>
      </div>
      
      <div [class.slices__item--show] = "displayTeenagerSlice" class="slices__item ">
        <input
          [checked]="stepForm.value.slice === '3'"
          type="radio"
          name="slice"
          formControlName="slice"
          [value]="'3'"
        />
        <div class="slices__slice slices__slice--primado"></div>
      </div>
    </div>
  </div>
  <div>
    <p class="label">INFORMATIONS JEUNE PATIENT</p>
    <div class="item">
      <username-predict
        (usernameSend)="usernameSend($event)"
        [username]="patient[0].username"
        [lastname]="stepForm.value.lastname"
        [firstname]="stepForm.value.firstname"
 
      ></username-predict>
    </div>
    <div class="item">
      <label class="m-label">Nouveau mot de passe</label>
      <input formControlName="password" class="m-input" type="password" />
      <div
        *ngIf="stepForm.get('password')?.hasError('required') && submitted"
        class="m-error"
      >
        Une mot de passe est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label">Confirmez</label>
      <input formControlName="confirmPassword" class="m-input" type="password" />
      <div *ngIf="passworConfirmed" class="m-error">
        Le mot de passe n'est pas le meme
      </div>
    </div>
    <div>
      <input type="submit" class="m-button" value="Suivant" />
    </div>
  </div>
</form>
