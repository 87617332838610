import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersRoutes } from 'src/core/constants/users.routes';
import { ArticleComponent } from './home/components/article/article.component';
import { HomeComponent } from './home/components/home/home.component';
import { MessengerComponent } from './messenger/components/messenger/messenger.component';
import { InvitationConfirmComponent } from './users/components/invitation/invitation-confirm/invitation-confirm.component';
import { InvitationErrorComponent } from './users/components/invitation/invitation-error/invitation-error.component';
import { InvitationSigninComponent } from './users/components/invitation/invitation-signin/invitation-signin.component';
import { InvitationSuccessComponent } from './users/components/invitation/invitation-success/invitation-success.component';
import { InvitationComponent } from './users/components/invitation/invitation.component';
import { LoginComponent } from './users/components/login/login.component';
import { PatientComponent } from './users/components/patient/patient.component';
import { RepresentantComponent } from './users/components/representant/representant.component';
import { SubscribeComponent } from './users/components/subscribe/subscribe.component';
import { DisabledAccountComponent } from './users/components/disabled-account';
import { SubscribeSuccessComponent } from './users/components/subscribe-success/subscribe-success.component';



const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: UsersRoutes.LOGIN,
    component: LoginComponent,
  },
  { path: 'login/:hash', component: LoginComponent },
  {
    path: UsersRoutes.REGISTER,
    component: SubscribeComponent,
  },
  
  { 
    path: UsersRoutes.REGISTER_SUCCESS,
    component: SubscribeSuccessComponent,
  },
  { path: UsersRoutes.REGISTER_SUCCESS + '/:option', component: SubscribeSuccessComponent },
  {
    path: UsersRoutes.REPRESENTANT,
    component: RepresentantComponent,
  },
  {
    path: UsersRoutes.PATIENT + '/:id',
    component: PatientComponent,
  },
  {
    path: 'articles/:id',
    component: ArticleComponent,
  },
  { path: 'invitation/:hash', component: InvitationComponent },
  { path: 'invitation/:hash/error', component: InvitationErrorComponent },
  { path: 'invitation/:hash/confirm', component: InvitationConfirmComponent },
  { path: 'invitation/:hash/success', component: InvitationSuccessComponent },
  { path: 'invitation/:hash/signin', component: InvitationSigninComponent },
  { path: 'messenger', component: MessengerComponent },
  { path: 'disabled-account/:token', component: DisabledAccountComponent },
  { path: 'disabled-patient-account', component : DisabledAccountComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
