import { Component } from '@angular/core';

@Component({
  selector: 'app-representant',
  templateUrl: './representant.component.html',
  styleUrls: ['./representant.component.scss'],
})
export class RepresentantComponent {
  public displayedTab = 'informations';

  public display(tab: any) {
    this.displayedTab = tab;
  }
}
