<div class="main">
  <div class="head">
    <div class="container">
      <p class="title">
        LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
      </p>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="wrapper">
        <div class="infos">
          <img src="assets/images/login--character.png" />
          <div>
            <h1>
              Félicitation ! Vote compte <span>«Correspondant»</span> a été créé
              !
            </h1>
            <div class="buttons">
              <a [routerLink]="['/']">
                <div class="m-button m-button--border-blue">
                  Accéder à primakid
                </div>
              </a>

              <a [routerLink]="['/messenger']">
                <div class="m-button m-button--border-blue">
                  Accéder au chat
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
