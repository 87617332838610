import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user-service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
})
export class PatientComponent {
  public patient: any;
  public patient_id: any;
  public displayedTab = 'informations';

  constructor(
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  /*
   * Surveille le changement du parametre "id" ( patient id ) dans l'url
   */
  ngOnInit() {
    this.route.params
    .subscribe((value: any) => {
      
      this.patient_id = value.id;
      this.getPatient();
    });
    
    
   

  }

  public display(tab: any) {
    this.displayedTab = tab;
  }

  

  /*
   * Recupere les informations du patient
   */
  private getPatient() {
    this.patient = this.userService.getUserPatient(this.patient_id)[0];
  }
}
