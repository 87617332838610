import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SocketService } from '@core/services/socket';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-connected',
  templateUrl: './connected.component.html',
  styleUrls: ['./connected.component.scss'],
  providers: [UserService, SocketService],
})
export class ConnectedComponent {
  @Output() slice = new EventEmitter();

  @Input() connectedUser: any;
  @Input() isHome: boolean;
  @Input() isMessenger: boolean;

  public displayedMenu: boolean = false;
  public waitingMessages: number = 0;

  public userCanAccessMessenger: Boolean = false;

  constructor(
    private user: UserService,
    private router: Router,
    private socket: SocketService
  ) {}

  ngOnInit() {
    console.log(this.connectedUser)
    if (this.isHome) {
      this.socket.connectUser().then(
        (userIsConnected: any) => {
          this.userCanAccessMessenger = true;

          this.socket.closeRoom().then(
            (success) => {
              this.socket.unsetListeners();
              let socketListener: any = this.socket.getSocket();

              socketListener.on('chat_message', (message: any) => {
                this.waitingMessages++;
              });
            },
            (error) => console.log('impossible de fermer la conversation...')
          );

          this.socket
            .getWaitingMessages()
            .then((counter) => (this.waitingMessages = counter));
        },
        (userIsNotConnected) => (this.userCanAccessMessenger = false)
      );
    }
  }

  /*
   * Deconnecte le representant légale
   */
  public logout() {
    this.socket.disconnect();
    this.user.unsetUser();
    this.router.navigate(['/']);
  }

  /*
   * Quand l'utilisateur change sa tranche d'age
   */
  public sliceChanged(slice: any) {
    this.slice.emit(slice);
  }

  /*
   * Affichage du menu en responsive
   */
  public toogleMenu() {
    this.displayedMenu
      ? (this.displayedMenu = false)
      : (this.displayedMenu = true);
  }
}
