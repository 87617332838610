import { Component, OnInit, Output, EventEmitter, Input, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { telephoneValidator } from '@core/validators/checkPhone';
import { passwordMatchingValidatior } from '@core/validators/confirmPassword';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  @Input() representant: any;
  @Output() changeStep = new EventEmitter();

 
  public emailExist: boolean = false;
  public passworConfirmed: boolean = false;
  public submitted = false;
  stepForm: FormGroup;
  public errorForm = false;

  public username: any;

  constructor(private user: UserService,private elRef: ElementRef) {
  
  }

  ngOnInit(): void {
    const element = this.elRef.nativeElement.querySelector('#formSubscribe');
 
    window.scrollTo({top: element.offsetTop - 170, behavior: 'smooth'});
    this.stepForm = new FormGroup(
      {
        username: new FormControl(this.representant.username),
        lastname: new FormControl(this.representant.lastname, [Validators.required]),
        firstname: new FormControl(this.representant.firstname, [Validators.required]),
        birthday: new FormControl(this.representant.birthday, [Validators.required]),
        email: new FormControl(this.representant.email, [Validators.required]),
        address: new FormControl(this.representant.address, [Validators.required]),
        zip: new FormControl(this.representant.zip, [Validators.required]),
        city: new FormControl(this.representant.city, [Validators.required]),
        phone: new FormControl(this.representant.phone, [Validators.required, telephoneValidator]),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(4),
        ]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      { validators: passwordMatchingValidatior }
    );
  }

  changeEmail(event: any) {
    const email = event.target.value;
    this.user.emailIsAssigned(email).subscribe((response: any) => {
      if (response.data) {
        this.emailExist = true;
      } else {
        this.emailExist = false;
      }
    });
  }

  usernameSend(event: any) {
    this.username = event;
    this.stepForm.value.username = event;
  }
  onSubmit() {
    this.submitted = true;
    this.errorForm = false;
    if(!this.emailExist) {
      if (this.stepForm.errors && this.stepForm.errors['notmatched']) {
        this.passworConfirmed = true;
      } else {
        this.passworConfirmed = false;
      }
  
      if (this.stepForm.valid && !this.passworConfirmed) {
        this.changeStep.emit(this.stepForm.value);
      } else { 
        this.errorForm = true;
      }
    }

    
  }
}
