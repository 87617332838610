import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { passwordMatchingValidatior } from '@core/validators/confirmPassword';
import * as moment from 'moment';
import { MessageComponent } from 'src/app/core/components/message/message.component';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-representant-password',
  templateUrl: './represantant-password.component.html',
  styleUrls: ['./represantant-password.component.scss'],
  providers: [MessageComponent],
})
export class RepresantantPasswordComponent {
  passwordForm: FormGroup;
  public validationMessage: any;
  public submitted = false;
  public submitButtonState: string = 'save';

  public newPassword: string;
  public newPasswordConfirmed: Boolean = false;

  public userPassword: string;
  public passwordConfirmed: any;

  public username: any;

  constructor(private user: UserService, private message: MessageComponent) {
    this.username = this.user.getUser().data.username;

    this.passwordForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(4),
        ]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      { validators: passwordMatchingValidatior }
    );
  }

  /*
   * Recupere le resultat du component password-tester
   */
  public userPasswordConfirmed(result: any) {
    this.passwordConfirmed = result;
  }

  /*
   * Recupere le password et le "pass" ( true / false ) du component password-confirm
   */
  public changePassword(password: any) {
    this.newPassword = password.password;
    this.newPasswordConfirmed = password.pass;
  }

  /*
   * Recupere le mot de passe de l'utilisateur
   */
  private getUserPassword() {
      this.userPassword = this.user.getUser().password;
  }

  /*
   * Enregistre le nouveau mot de passe
   */
  public validate() {
    if (this.passwordConfirmed && this.newPasswordConfirmed) {
     
      this.submitted = true;
      let user = this.user.getUser().data;
      user.birthday = moment(user.birthday).format('DD/MM/YYYY');
      user.password = this.newPassword;

      delete user.patients;

      this.user.updateUser(user.id, user).subscribe((serverResponse: any) => {
        const response = serverResponse;
        this.submitted = false;
        const user = {
          username: response.data.username,
          password: this.user.getUser().password,
        };

        this.user.setUser(user.username, user.password, response.data);
        this.validationMessage = this.message.success(response.message);
        this.getUserPassword();
        this.passwordForm.reset();
      }, error => {
        this.submitted = false;
        
      });

      
    }
  }
}
