import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() sliceSelected = new EventEmitter();
  public configuration: any = {
    home: false,
    user: false,
    messenger: false,
  };

  constructor(private user: UserService, private router: Router) {
   
    router.events.subscribe((val: any) => {
      this.configureHeader(router.url);
    });
  }

  private configureHeader(url: string) {
 
    url == '/'
      ? (this.configuration.home = true)
      : (this.configuration.home = false);
    url == '/messenger'
      ? (this.configuration.messenger = true)
      : (this.configuration.messenger = false);

    this.user.getUser()
      ? (this.configuration.user = this.user.getUser())
      : (this.configuration.user = false);
  }

  public slice(slice: any) {
    this.user.SliceSend(slice);
  }
}
