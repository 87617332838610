import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { passwordMatchingValidatior } from '@core/validators/confirmPassword';

import { UserService } from 'src/app/users/services/user-service';
import * as moment from 'moment';
import { MessageComponent } from 'src/app/core/components/message/message.component';
import { telephoneValidator } from '@core/validators/checkPhone';


@Component({
  selector: 'app-representant-informations',
  templateUrl: './representant-informations.component.html',
  styleUrls: ['./representant-informations.component.scss'],
  providers: [MessageComponent],
})
export class RepresentantInformationsComponent {
  informationForm: FormGroup; 
  public representant: any;
  public emailExist: boolean = false;
  public submitted = false;
  public loading = false;
  public submitButtonState: string = 'save';
  public validationMessage: any;
  public errorMessage = false;
  constructor(private user: UserService, private message: MessageComponent) {
    this.representant = this.user.getUser().data;

    this.informationForm = new FormGroup(
      {
        id: new FormControl(this.representant.id),
        role: new FormControl(this.representant.role),
        username: new FormControl(this.representant.username),
        lastname: new FormControl(this.representant.lastname, [
          Validators.required,
        ]),
        firstname: new FormControl(this.representant.firstname, [
          Validators.required,
        ]),
        birthday: new FormControl(
          moment(this.representant.birthday).format('YYYY-MM-DD'),
          [Validators.required]
        ),
        email: new FormControl(this.representant.email, [Validators.required]),
        address: new FormControl(this.representant.address, [
          Validators.required,
        ]),
        zip: new FormControl(this.representant.zip, [Validators.required]),
        city: new FormControl(this.representant.city, [Validators.required]),
        phone: new FormControl(this.representant.phone, [Validators.required, telephoneValidator]),
      },
      { validators: passwordMatchingValidatior }
    );
  }

  /*
   * Modifie l'etat du boutton "enregistrer"
   */
  public toggleSubmitButton() {
    if (this.submitButtonState == 'save') this.submitButtonState = 'loading';
    else this.submitButtonState = 'save';
  }

  usernameSend(event: any) {
    this.representant = event;
    this.informationForm.value.username = event;
  }

  changeEmail(event: any) {
    const email = event.target.value;
    this.user.emailIsAssigned(email).subscribe((response: any) => {
      if (response.data) {
        this.emailExist = true;
      } else {
        this.emailExist = false;
      }
    });
  }

  /*
   * Enregistre les nouvelles données du representant
   */
  public onSubmit() {
    this.submitted = true;
  
  
    if (this.informationForm.valid) {
      this.loading = true;
      this.submitted = true;
      this.toggleSubmitButton();
      this.informationForm.value.birthday = moment(
        this.representant.birthday
      ).format('DD/MM/YYYY');
      this.user
        .updateUser(this.representant.id, this.informationForm.value)
        .subscribe((serverResponse: any) => {
          const response = serverResponse;
          this.loading = false;
          const user = {
            username: response.data.username,
            password: this.user.getUser().password,
          };

          this.user.refreshUserData();
          this.validationMessage = this.message.success(response.message);
          this.submitted = false;
          this.loading = false;
        }, error => {
          console.log(error)
          this.submitted = false;
          this.loading = false;
          this.errorMessage = true;
        });
    } 
  }
}
