import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { TokenService } from '../token-service/token.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class RequestManagerService {
  private apiUrl = environment.baseUrl;

  public token: any = localStorage.getItem('token');
  subscription: Subscription;

  constructor(private http: HttpClient, private tokenservice: TokenService) {
    this.subscription = this.tokenservice.getToken().subscribe((token) => {
      this.token = token;
    });
  }

  /**
   * @param parameter
   * @param {boolean} header
   * @returns {{headers: any; params: any}}
   */

  requestHeaderParams(
    parameter?: any,
    header?: boolean,
    observe?: any,
    reportProgress?: boolean
  ) {
    let headerOptions;
    let httpParams: any;

    if (parameter) {
      httpParams = new HttpParams();
      Object.keys(parameter).forEach(function (key) {
        httpParams = httpParams.append(key, parameter[key]);
      });
    }

    if (header) {
      headerOptions = new HttpHeaders()
        .set('Authorization', 'Bearer ' + this.token)
        .set('Accept', 'application/json')
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('Content-type', 'application/json');
    }

    const httpOptions = {
      headers: headerOptions,
      params: httpParams,
      observe: observe,
      reportProgress: reportProgress,
    };
    return httpOptions;
  }

  requestHeaderBasic(user: any = undefined) {
    let headerOptions;
    headerOptions = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-type', 'application/json');

    if (user == undefined && localStorage.getItem('user') != null) {
      user = localStorage.getItem('user');
      user = JSON.parse(user);

      headerOptions = headerOptions.append(
        'Authorization',
        'Basic ' + window.btoa(user.data.username + ':' + user.password)
      );
    } else if (user) {
      headerOptions = headerOptions.append(
        'Authorization',
        'Basic ' + window.btoa(user.username + ':' + user.password)
      );
    }

    const httpOptions = {
      headers: headerOptions,
    };
    return httpOptions;
  }

  /**
   * Envoie une requète get a lapi
   * @param {string} endPoint
   * @param parameter
   * @param header
   * @returns {Observable<Object>}
   */

  get(
    endPoint: string,
    parameter?: any,
    header?: any,
    observe?: any,
    reportProgress?: boolean
  ) {
    return this.http.get(
      this.apiUrl + endPoint,
      this.requestHeaderParams(parameter, header, observe, reportProgress)
    );
  }

  getBasic(endPoint: string, parameter?: any) {
    return this.http.get(
      this.apiUrl + endPoint,
      this.requestHeaderBasic(parameter)
    );
  }

  /**
   * Envoie une requète post a lapi
   * @param {string} endPoint
   * @param parameter
   * @param header
   * @param other
   * @returns {Observable<ArrayBuffer>}
   */

  post(
    endPoint: string,
    parameter?: any,
    header?: any,
    basic?: any,
    fullUrl?: boolean
  ) {
    let httpOptions;
    if (header) {
      httpOptions = {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token),
      };
    }
    if (basic) {
      let user: any;
      user = localStorage.getItem('user');
      if (user) {
        console.log(user);
        user = JSON.parse(user);
        let basicData = window.btoa(user.username + ':' + user.password);

        if (header) {
          httpOptions = {
            headers: new HttpHeaders()
              .set('Authorization', 'Basic ' + basicData)
              .set('Content-type', 'application/json')
              .set('Accept', 'application/json'),
          };
        }
      }
    }
    if (fullUrl) {
      return this.http.post(this.fullUrl(endPoint), parameter, httpOptions);
    } else {
      return this.http.post(this.apiUrl + endPoint, parameter, httpOptions);
    }
  }

  /**
   *
   * @param {string} endPoint
   * @param parameter
   * @param header
   * @returns {Observable<ArrayBuffer>}
   */

  delete(endPoint: string, header?: any, basic?: any, fullUrl?: boolean) {
    let httpOptions;
    console.log(header, basic, fullUrl);
    if (header) {
      httpOptions = {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token),
      };
    }
    if (basic) {
      console.log('basic', basic);
      let user: any;
      user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
        let basicData = window.btoa(user.username + ':' + user.password);
        console.log('user', user);
        if (header) {
          httpOptions = {
            headers: new HttpHeaders()
              .set('Authorization', 'Basic ' + basicData)
              .set('Content-type', 'application/json')
              .set('Accept', 'application/json'),
          };
        }
      }
    }

    if (fullUrl) {
      return this.http.delete(this.fullUrl(endPoint), httpOptions);
    } else {
      return this.http.delete(this.apiUrl + endPoint, httpOptions);
    }
  }

  /**
   *
   * @param {string} endPoint
   * @param parameter
   * @param header
   * @returns {Observable<ArrayBuffer>}
   */

  put(
    endPoint: string,
    parameter?: any,
    header?: any,
    basic?: any,
    fullUrl?: boolean
  ) {
    let httpOptions;
    if (header) {
      httpOptions = {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token),
      };
    }
    if (basic) {
      let user: any;
      user = localStorage.getItem('user');
      if (user) {
        console.log(user);
        user = JSON.parse(user);
        let basicData = window.btoa(user.username + ':' + user.password);

        if (header) {
          httpOptions = {
            headers: new HttpHeaders()
              .set('Authorization', 'Basic ' + basicData)
              .set('Content-type', 'application/json')
              .set('Accept', 'application/json'),
          };
        }
      }
    }
    if (fullUrl) {
      return this.http.put(this.fullUrl(endPoint), parameter, httpOptions);
    } else {
      return this.http.put(this.apiUrl + endPoint, parameter, httpOptions);
    }
  }

  putWithJson(endPoint: string, parameter?: any, header?: any) {
    let httpOptions;
    let user: any;
    user = localStorage.getItem('user');

    if (user) {
      user = JSON.parse(user);
      let basicData = window.btoa(user.username + ':' + user.password);

      if (header) {
        httpOptions = {
          headers: new HttpHeaders()
            .set('Authorization', 'Basic ' + basicData)
            .set('Content-type', 'application/json'),
        };
      }
    }

    return this.http.put(this.apiUrl + endPoint, parameter, httpOptions);
  }

  fullUrl(apiEndPoint: any) {
    let params = apiEndPoint.split('?');

    if (params.length == 1) return this.apiUrl + apiEndPoint + '.json';
    else return this.apiUrl + params[0] + '.json?' + params[1];
  }
}
