import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '@core/services/config';
import { UserService } from 'src/app/users/services/user-service';
import { HomeService } from '../services/home-service';
import { Socket } from 'ngx-socket-io';
import { SocketService } from '@core/services/socket';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  // Parametres de la requete pour filtrer les contenus

  
  public subscription: any;
  public contentFilters = {
    slice: 1,
    category: 'all',
    page: 1,
    perPage: 16,
  };

  public links: Array<any>;
  public pagination: any;
  public loading: boolean = true;
  public loadingUser: Boolean = true;
  public userCanAccessMessenger : Boolean = false;
  public waitingMessages : number = 0;
  public userInfos: any;

  constructor(
    private config: Config,
    private router: Router,
    private home: HomeService,
    private user: UserService,
    private socket: SocketService
  ) {
    this.subscription = this.user.SliceGet().subscribe((slice: any) => {
      this.contentFilters.slice = slice;
    });
  }

  ngOnInit() {
  
    this.getContent();
    this.socket.connectUser().then(
      userIsConnected => {
        this.userInfos = this.user.getUser();
        console.log(this.userInfos)
        this.userCanAccessMessenger = true
        
        this.socket.closeRoom().then(
          success => {
            this.loadingUser = false;
            this.socket.unsetListeners()
                let socketListener : any = this.socket.getSocket()

                socketListener.on('chat_message', (message: any) => {
                  this.waitingMessages++              
                })
          },
          error => {
            this.loadingUser = false;
            console.log('impossible de fermer la conversation...')
          } 
        )

        this.socket.getWaitingMessages().then( counter => this.waitingMessages = counter )
      }, 
      userIsNotConnected => {
        this.userCanAccessMessenger = false
        this.loadingUser = false;
      }
    )
  }

  /*
   * Si l'utilisateur selectionne une catégorie ( jeu, musique, dessin ... ) on ajoute la categorie aux filtres de contenu
   * Si l'utilisateur déselectionne une catégorie on enleve la categorie des filtres de contenu
   */
  public applyCategoryFilter(category: any) {
    if (category != this.contentFilters.category)
      this.contentFilters.category = category;
    else this.contentFilters.category = 'all';

    if (category == 'all') this.contentFilters.category = 'all';

    this.contentFilters.page = 1;
    this.getContent();
  }

  public applyCategoryFilterEvent(event: any) {
    if (event.target.value != this.contentFilters.category)
      this.contentFilters.category = event.target.value;
    else this.contentFilters.category = 'all';

    if (event.target.value == 'all') this.contentFilters.category = 'all';

    this.contentFilters.page = 1;
    this.getContent();
  }

  /*
   * Recupere les contenus
   */
  public getContent() {
    this.loading = true;
    this.home.getPosts(this.getContentUrl()).subscribe((x: any) => {
      this.links = x.data;
      this.pagination = x.paging;

      this.loading = false;
    });
  }

  /*
   * Recupere le tranche d'âge selectionné par l'utilisateur ( slice , selectionné dans le header ), puis recupere les contenus
   */
  public getSelectedSlice(slice: number) {
    if (slice != this.contentFilters.slice) {
      this.contentFilters.slice = slice;
      this.contentFilters.page = 1;
      this.getContent();
    }
  }

  /*
   * Création de l'url à appeler pour recuperer les contenus en fonction des filtres appliqués
   */
  private getContentUrl() {
    let url =
      'contents?slice=' +
      this.contentFilters.slice +
      '&perPage=' +
      this.contentFilters.perPage;

    if (this.contentFilters.category != 'all')
      url += '&category=' + this.contentFilters.category;

    if (this.contentFilters.page != 1)
      url += '&page=' + this.contentFilters.page;

    if (this.isMobileDevice()) url += '&is_desktop=false';
    else url += '&is_desktop=true';

    return url;
  }

  private isMobileDevice() {
    return (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    );
  }

  /*
   * Retourn l'url de l'image du lien
   */
  public getLinkImageSrc(link: any) {
    if (link.picture) {
      if (link.type == 'link')
        return (
          this.config.imgLinkUrl +
          '/' +
          link.picture_dir +
          '/thumb_' +
          link.picture
        );
      else
        return (
          this.config.imgArticleUrl +
          '/' +
          link.picture_dir +
          '/thumb_' +
          link.picture
        );
    } else if (typeof link.category == 'object')
      return 'assets/images/links--news.jpg';
    else {
      let src: string = '';

      switch (link.category) {
        case 'games':
          src = 'assets/images/links--gaming.jpg';
          break;
        case 'learn':
          src = 'assets/images/links--learning.jpg';
          break;
        case 'drawings':
          src = 'assets/images/links--drawing.jpg';
          break;
        case 'clips':
          src = 'assets/images/links--video.jpg';
          break;
        case 'news':
        case 'article':
          src = 'assets/images/links--news.jpg';
          break;
      }

      return src;
    }
  }

  /*
   * Creation de la pagination ( trick angular2 )
   */
  public createPagination(number: number) {
    var items: number[] = [];

    for (var i = 1; i <= number; i++) {
      items.push(i);
    }

    return items;
  }

  /*
   * Changement de page via la pagination, ajout de la page séléctionné dans les filtres de contenu et relance le requete pour recuperer le contenu
   */
  public goPage(page: any) {
    if (page != this.contentFilters.page) {
      this.contentFilters.page = page;
      this.getContent();
      const el = document.getElementById('content');
      if (el) {
        el.scrollIntoView(true);
      }
    }
  }

  /*
   * Ouvre le lien dans un nouvel onglet et donne le focus à cet onglet
   */
  private openInNewTab(url: any) {
    const tab: any = window.open(url, '_blank');
    tab.focus();
  }

  /*
   * Definie si le lien doit etre ouvert dans l'application ( un article ) ou dans un nouvel onglet
   */
  public openLink(link: any) {
    if (link.type === 'article') {
      this.home.postArticleSeen('contents/articles/seen/' + link.id);
      this.router.navigate(['/articles', link.id]);
    } else {
      this.home.postLinksSeen('contents/articles/seen/' + link.id);

      this.openInNewTab(link.link);
    }
  }
}
