import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-representant-status',
  templateUrl: './representant-status.component.html',
  styleUrls: ['./representant-status.component.scss'],
})
export class RepresentantStatusComponent {
  public disableAccountBtnText: string = 'Désactiver mon compte';
  public deleteAccountBtnText: string = 'Supprimer mon compte';

  public modalDisable: boolean = false;
  public modalDelete: boolean = false;

  constructor( 
    private router: Router,
		private user           : UserService,

	){}

  public openModal(status: string) {
    if (status == 'disabled') {
      this.modalDisable = true;
    } else {
      this.modalDelete = true;
    }
  }

  public closeModal() {
    this.modalDelete = false;
    this.modalDisable = false;
  }

  public stopPropagation(e: any) {
    e.stopPropagation();
  }

  private formatedBirthdayDate(date: any){
		let buffer = new Date(date)
	
		return buffer.getDate()+'/'+(buffer.getMonth()+1)+'/'+buffer.getFullYear()
	}

  public disableAccount(){
		this.disableAccountBtnText = "Désactivation en cours"

		const user  = this.user.getUser()

		delete user.data.patients
		delete user.data.representant

		user.data.birthday = this.formatedBirthdayDate(user.data.birthday)

		user.data.status = 'disabled'

    this.user
    .updateUser(user.data.id, user.data)
    .subscribe((serverResponse: any) => {
     
        const response = serverResponse;
        console.log(response)
        this.user.unsetUser();
        this.router.navigate(['login']);
         	
    })
	}

  
	public deleteAccount(){
		this.deleteAccountBtnText = "Suppression en cours"

		const user  = this.user.getUser()
		
		this.user.deleteUser(user.data.id).subscribe( (serverResponse: any) => {
          const response = serverResponse;

          this.user.unsetUser()
         	this.router.navigate(['login'])
        })
	}

}
