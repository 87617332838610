<form [formGroup]="informationForm" (submit)="onSubmit()">
  <div class="item">
    <label class="m-label">Nom</label>
    <input
      [class.error]="
        informationForm.get('lastname')?.hasError('required') && submitted
      "
      class="m-input"
      type="text"
      formControlName="lastname"
    />
    <div
      *ngIf="informationForm.get('lastname')?.hasError('required') && submitted"
      class="m-error"
    >
      Le nom est requis
    </div>
  </div>
  <div class="item">
    <label class="m-label">Prénom</label>
    <input
      [class.error]="
        informationForm.get('firstname')?.hasError('required') && submitted
      "
      class="m-input"
      type="text"
      formControlName="firstname"
    />
    <div
      *ngIf="
        informationForm.get('firstname')?.hasError('required') && submitted
      "
      class="m-error"
    >
      Le prénom est requis
    </div>
  </div>
  <div class="item">
    
   
    <username-predict
      (usernameSend)="usernameSend($event)"
      [lastname]="informationForm.value.lastname"
      [firstname]="informationForm.value.firstname"
      [username]="informationForm.value.username"
    ></username-predict>
  </div>

  <div class="item">
    <label class="m-label">NÉ(E) LE : JJ/MM/AAAA</label>
    <input
      [class.error]="
        informationForm.get('birthday')?.hasError('required') && submitted
      "
      class="m-input"
      type="date"
      formControlName="birthday"
    />

    <div
      *ngIf="informationForm.get('birthday')?.hasError('required') && submitted"
      class="m-error"
    >
      La date de naissance est requis
    </div>
  </div>

  <app-facilities-list
    [serviceID]="informationForm.controls['service_id'].value"
    [etablishmentIdData]="patient.service.establishment_id"
    [errorEtablisment]="
      informationForm.get('etablishment')?.hasError('required') && submitted
    "
    [errorService]="
      informationForm.get('service_id')?.hasError('required') && submitted
    "
    (serviceSelected)="serviceSelected($event)"
    (etablismentSelected)="etablismentSelected($event)"
  ></app-facilities-list>
 
  <input type="submit" class="m-button" [value]="submitted ? 'Chargement...' : 'Enregistrer'" />
  <app-message [message]="validationMessage"> </app-message>
</form>
