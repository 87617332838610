<footer class="main">
  <div class="top">
    <div class="container">
      <div class="topBox">
        <p>
          <img
            class="footer__character"
            src="assets/images/footer--character.png"
          />
          <span class="question">Des questions ?</span>
          <a href="mailto:contact.assoprima@gmail.com" class="contact">Contacter</a>
        </p>
      </div>
    </div>
  </div>
  <div class="bottom">
    <img src="assets/images/footer--logo.png" />
  </div>
</footer>
