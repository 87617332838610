import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestManagerService } from '@core/services/request-manager-service/request-manager.service';
import { InvitationTools } from 'src/app/users/services/invitation-service';
import { UserService } from 'src/app/users/services/user-service';

@Component({
  selector: 'app-invitation-confirm',
  templateUrl: './invitation-confirm.component.html',
  styleUrls: ['./invitation-confirm.component.scss'],
})
export class InvitationConfirmComponent {
  public hash: string;
  public contact: any;
  public decodedInvitation: any;

  public loaderMessage: string = "Lecture de l'invitation ...";
  public displayLoader: Boolean = true;

  public displaySwitchAccountBtn: Boolean = true;
  public invitationAccepted: Boolean = false;

  //content
  public pageContent: any = {
    title: "Confirmer <span>l'invitation",
    content: '',
  };

  constructor(
    private user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private invitation: InvitationTools,
    private requestService: RequestManagerService
  ) {}

  /*
   * Ecouteur sur le Hash de l'url
   */
  ngOnInit() {
    const params = this.route.snapshot.paramMap.get('hash');
    if (params) {
      this.hash = params;
    }
    if (!this.user.getUser()) this.invitation.signin(this.hash);
    else {
      this.invitation.read(this.hash).subscribe(
        (decodedInvitation: any) => {
          this.decodedInvitation = decodedInvitation.data;

          this.contact = this.decodedInvitation.invitations[0].patient;
          this.initConfirmPage();
        },
        (error) => {
          this.invitation.displayError(this.hash);
        }
      );
    }
  }

  /*
   * Verifie que l'invitation soit pour l'utilisateur connecté
   */
  private invitationIsForConnectedUser() {
    const user = this.user.getUser().data;

    return (
      this.decodedInvitation.username &&
      this.decodedInvitation.username == user.username
    );
  }

  /*
   * L'invitation concerne un utilisateur ?
   */
  private invitationIsForAnUser() {
    return this.decodedInvitation.username;
  }

  /*
   * Demande à l'utilisateur de valider l'ajout du contact à son compte
   */
  private initConfirmPage() {
    if (this.invitationIsForAnUser()) {
      // Si l'invitation est adressé à un utilisateur existant mais pas à l'utilisateur connecté
      if (!this.invitationIsForConnectedUser())
        this.invitation.displayError(this.hash);
      // Si l'invitation est addrésé à l'utilisateur connecté
      else if (this.invitationIsForConnectedUser()) {
        this.pageContent.content =
          'Voulez-vous accepter l’invitation de <span>' +
          this.contact.firstname +
          ' ' +
          this.contact.lastname +
          '</span> ?';
        this.displaySwitchAccountBtn = false;
      }
    }
    // Si l'invitation est envoyé à un utilisateur inexistant mais qu'on veut l'associer à un compte existant
    else {
      const user = this.user.getUser().data;
      this.pageContent.content =
        'Voulez-vous accepter l’invitation de <span>' +
        this.contact.firstname +
        ' ' +
        this.contact.lastname +
        '</span><br>en tant que <span>' +
        user.firstname +
        ' ' +
        user.lastname +
        '</span> ?';
    }

    // Masque le chargement
    this.displayLoader = false;
  }

  /*
   * Retourne l'url pour accepter l'invitation ( merge ou confirm )
   */
  public confirmInvitationUrl() {
    if (this.decodedInvitation.username)
      return 'contacts/confirm/' + this.hash + '/accept';
    else return 'contacts/confirm/' + this.hash + '/merge';
  }

  /*
   * Validation de la demande de contact
   */
  public validateContact() {
    this.loaderMessage = "Validation de l'invitation ...";
    this.displayLoader = true;

    this.requestService.post(this.confirmInvitationUrl(), []).subscribe(
      (success) => {
        this.displayLoader = false;
        this.invitationAccepted = true;

        this.pageContent.title = '<b>Invitation</b> acceptée';
        this.pageContent.content =
          "L'invitation de <span>" +
          this.contact.firstname +
          ' ' +
          this.contact.lastname +
          '</span> a bien été acceptée.';
      },
      (error) => {
        this.displayLoader = false;
        this.invitationAccepted = true;

        this.pageContent.title = '<b>Erreur</b> invitation';
        this.pageContent.content =
          "L'invitation de <span>" +
          this.contact.firstname +
          ' ' +
          this.contact.lastname +
          "</span> n'a pas pu étre acceptée. Merci de réessayer.";
      }
    );
  }
}
