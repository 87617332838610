import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-password-confirm',
  templateUrl: './password-confirm.component.html',
  styleUrls: ['./password-confirm.component.scss'],
})
export class PasswordConfirmComponent {
  @Output() passwordChange = new EventEmitter();

  public hash = new Date().getTime();
  public errorPasswordSame = false;
  public errorPasswordFormat = false;

  public password = {
    password: '',
    confirm: '',
  };

  private testLength() {
    return (
      this.password.password.length >= 4 && this.password.confirm.length >= 4
    );
  }

  private testEmpty() {
    return this.password.password != '' && this.password.confirm != '';
  }

  /*
   * Compare les mots de passe
   */
  public testPassword() {
    if (this.testLength() && this.testEmpty()) {
      this.errorPasswordFormat = false;
      let pass = true;

      if (this.password.password != this.password.confirm) {
        this.errorPasswordSame = true;

        pass = false;
      }

      this.passwordChange.emit({
        password: this.password.password,
        pass: pass,
      });
    } else {
      this.errorPasswordFormat = true;
      this.errorPasswordSame = false;
    }
  }
}
