<div class="main">
  <div class="head">
    <div class="container">
      <p class="title">
        LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
      </p>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div *ngIf="loading" class="loader">
        Recherche de l'article ...
        <div class="uil-ripple-css" style="transform: scale(0.66)">
          <div></div>
          <div></div>
        </div>
      </div>
      <div *ngIf="article" class="article">
        <div class="return">
          <button [routerLink]="['/']" class="m-button m-button--border-blue">
            Retour
          </button>
        </div>
        <div class="article-header">
          <h1 class="article-title">
            {{ article.name }}
          </h1>

          <div class="category">
            <span>Catégorie : </span>
            <b>{{ article.category.name }}</b>
          </div>
        </div>
        <div class="article-content">
          <div class="m-wysiwyg" [innerHTML]="article.content"></div>
        </div>
      </div>
    </div>
  </div>
</div>
