<div class="main">
  <div class="head">
    <div class="container">
      <p class="title">
        LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
      </p>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="wrapper">
        <div class="infos">
          <img src="assets/images/login--character.png" />
          <div>
            <h1 [innerHTML]="pageContent.title"></h1>

            <p class="text" [innerHTML]="pageContent.content"></p>

            <p class="accept">
              <button
                (click)="validateContact()"
                class="m-button m-button--border-blue"
              >
                Accepter l'invitation
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
