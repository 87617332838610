<form [formGroup]="contactForm" (submit)="validate()">
  <p class="title">Invitez un nouveau correspondant :</p>
  <div>
    <div class="item">
      <label class="m-label">ADRESSE E-MAIL DU CORRESPONDANT </label>
      <input
        [class.error]="
          contactForm.get('email')?.hasError('required') && submitted
        "
        class="m-input"
        type="text"
        formControlName="email"
      />

      <div
        *ngIf="contactForm.get('email')?.hasError('required') && submitted"
        class="m-error"
      >
        Un email est requis
      </div>
    </div>
    <div class="item">
      <label class="m-label"
        >PERSONNALISER LE MESSAGE QUI SERA ADRESSÉ DANS VOTRE INVITATION AU
        CORRESPONDANT :</label
      >

      <textarea
        class="m-textarea"
        name="comment"
        rows="300"
        formControlName="comment"
      >
      </textarea>
    </div>
  </div>
  <div *ngIf="alertMessage.status">
    <app-message [message]="alertMessage.invitation"> </app-message>
  </div>

  <div class="submit">
    <input
      type="submit"
      value="Envoyer l'invitation"
      class="m-button m-button--border-blue"
    />
  </div>
</form>

<div class="waiting">
  <p class="title">Demandes en attentes:</p>
  <table
    *ngIf="!loading.waitingContacts && waitingContacts.length > 0"
    class="table"
  >
    <tr class="table__row" *ngFor="let contact of waitingContacts">
      <td class="table__data">{{ contact.email }}</td>
    </tr>
  </table>

  <table
    *ngIf="!loading.waitingContacts && waitingContacts.length == 0"
    class="table empty"
  >
    <tr class="table__row">
      <td class="table__data">Aucune demande en attente</td>
    </tr>
  </table>
</div>

<div class="contacts">
  <p class="title">Correspondants actifs :</p>
  <table class="table" *ngIf="!loading.approvedContacts">
    <tr class="table__row">
      <th class="table__head">Prénom</th>
      <th class="table__head">NOM</th>
      <th class="table__head">E-mail</th>
      <th class="table__head">Supprimer</th>
    </tr>
    <tr class="table__row" *ngFor="let contact of approvedContacts">
      <td class="table__data">{{ contact.firstname }}</td>
      <td class="table__data">{{ contact.lastname }}</td>
      <td class="table__data">{{ contact.email }}</td>
      <td
        class="table__data table__data--remove"
        [class.no-cursor]="isRepresentantContact(contact.id)"
      >
        <button
          (click)="selectContactToDelete(contact)"
          *ngIf="!isRepresentantContact(contact.id)"
          type="button"
        >
          Supprimer
        </button>
      </td>
    </tr>
  </table>
</div>

<div (click)="closeModal()" *ngIf="modalDelete" class="m-modal">
  <div class="m-modal__box" (click)="stopPropagation($event)">
    <button (click)="closeModal()" type="button" class="m-modal__close">
      Fermer
    </button>
    <div class="m-modal__wrapper">
      <p class="m-modal__title">
        Suppression d'un contact de {{ patient.firstname }} ?
      </p>
      <p class="m-modal__text">
        Êtes-vous sûr de vouloir suprimer
        <span
          >{{ contactToDelete.firstname }} {{ contactToDelete.lastname }}</span
        >
        des contact de <span>{{ patient.firstname }}</span
        >? <br /><br />
        Vous ne pourrez pas annuler la suppression.
        <br /><br />
        Vous aurez la possibilité de réinviter
        {{ contactToDelete.firstname }} plus tard.
      </p>
      <p class="m-modal__button">
        <button
          (click)="deleteContact(contactToDelete.id)"
          class="m-button m-button--small m-button--border-blue"
        >
          Supprimer
        </button>
      </p>
    </div>
  </div>
</div>
