<div class="main">
  <div class="head">
    <div class="container">
      <p class="title">
        LE WEB GRATUIT ET SÉCURISÉ POUR LES ENFANTS HOSPITALISÉS
      </p>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <div class="wrapper">
        <div class="infos">
          <img src="assets/images/login--character.png" />
          <div>
            <h1>Connectez-vous <span>à PRIMAKID</span></h1>
            <p class="text">
              Saisissez <strong>vos codes d’accès</strong> pour vous connecter à
              Primakid !
            </p>
          </div>
        </div>
        <div class="login">
         
          <form [formGroup]="loginForm" (submit)="onSubmit()">
            <div class="loginBox">
              <div class="loginWrapper">
                <p class="loginLabel">Je me connecte :</p>
                <div  *ngIf="errorMessage" class="error">
                  <img src="assets/icons/info-fill.svg" />
                  Couple nom d'utilisateur / mot de passe incorrect.
                </div>
                <div class="item">
                  <input
                    [class.error]="
                      loginForm.get('username')?.hasError('required') &&
                      submitted
                    "
                    class="m-input"
                    type="text"
                    placeholder="Identifiant"
                    formControlName="username"
                  />
                  <p
                    *ngIf="
                      loginForm.get('username')?.hasError('required') &&
                      submitted
                    "
                    class="m-error"
                  >
                    L'identifiant est requis
                  </p>
                </div>
                <div class="item">
                  <input
                    [class.error]="
                      loginForm.get('password')?.hasError('required') &&
                      submitted
                    "
                    class="m-input"
                    type="password"
                    placeholder="Mot de passe"
                    formControlName="password"
                  />
                  <p
                    *ngIf="
                      loginForm.get('password')?.hasError('required') &&
                      submitted
                    "
                    class="m-error"
                  >
                    Le mot de passe est requis
                  </p>
                </div>

                <p class="register">
                  <a [routerLink]="['/subscribe']">
                    Pas encore de compte ? Je m'inscris.
                  </a>
                </p>
              </div>

              <div class="submit">
                <input type="submit" class="m-button" [value]="submitted ? 'Connexion en cours' : 'Valider'" />
              
              </div>
             
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
