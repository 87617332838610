import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { passwordMatchingValidatior } from '@core/validators/confirmPassword';
import { UserService } from 'src/app/users/services/user-service';
import * as moment from 'moment';
import { MessageComponent } from 'src/app/core/components/message/message.component';

@Component({
  selector: 'app-patient-informations',
  templateUrl: './patient-informations.component.html',
  styleUrls: ['./patient-informations.component.scss'],
  providers: [MessageComponent],
})
export class PatientInformationsComponent implements OnInit {
  @Input() patientID: any;
  informationForm: FormGroup;
  public patient: any;
  public certified: boolean = false;
  public submitted = false;
  public submitButtonState: string = 'save';
  public validationMessage: any;
  constructor(private user: UserService, private message: MessageComponent) {}

  ngOnInit(): void {
    this.patient = this.user.getUserPatient(this.patientID)[0];
   
    this.informationForm = new FormGroup(
      {
        id: new FormControl(this.patient.id),
        role: new FormControl(this.patient.role),
        username: new FormControl(this.patient.username),
        lastname: new FormControl(this.patient.lastname, [Validators.required]),
        firstname: new FormControl(this.patient.firstname, [
          Validators.required,
        ]),
        birthday: new FormControl(
          moment(this.patient.birthday).format('YYYY-MM-DD'),
          [Validators.required]
        ),
        etablishment: new FormControl(this.patient.service.establishment_id, [
          Validators.required,
        ]),
        relation: new FormControl(this.patient.relation, [
          Validators.required,
        ]),
        slice: new FormControl(this.patient.slice, [
          Validators.required,
        ]),
        service_id: new FormControl(this.patient.service.id, [
          Validators.required,
        ]),
      },
      { validators: passwordMatchingValidatior }
    );
    console.log(this.informationForm.value)
  }

  /*
   * Modifie l'etat du boutton "enregistrer"
   */
  public toggleSubmitButton() {
    if (this.submitButtonState == 'save') this.submitButtonState = 'loading';
    else this.submitButtonState = 'save';
  }

  usernameSend(event: any) {

    this.informationForm.value.username = event;
  }

  /*
   * Change le service du patient
   */
  public changeService(service: any) {
    this.patient.service.id = service;
  }

  public serviceSelected(event: any) {
    this.informationForm.patchValue({ service_id: event });
  }
  public etablismentSelected(event: any) {
    this.informationForm.patchValue({ etablishment: event });
  }

  /*
   * Enregistre les nouvelles données du representant
   */
  public onSubmit() {
    this.submitted = true;
    
    if (this.informationForm.valid) {
      console.log(this.informationForm.value)
      this.toggleSubmitButton();
      this.informationForm.value.birthday = moment(
        this.patient.birthday
      ).format('DD/MM/YYYY');
      delete this.informationForm.value.username
      delete this.informationForm.value.etablishment
      this.informationForm.value.service_id = parseInt( this.informationForm.value.service_id)

      this.user
        .updateUser(this.patient.id, this.informationForm.value)
        .subscribe((serverResponse: any) => {
          const response = serverResponse;
        
          this.user.refreshUserData();
          this.submitted = false;
          
          this.submitted = false;
         
          this.validationMessage = this.message.success(response.message);
        });
    }
  }
}
