<form [formGroup]="passwordForm" (submit)="validate()">
  <app-password-tester
    [username]="username"
    [userPassword]="userPassword"
    (passwordTested)="userPasswordConfirmed($event)"
  ></app-password-tester>

  <app-password-confirm
    (passwordChange)="changePassword($event)"
  ></app-password-confirm>

  <input type="submit" class="m-button" [value]="submitted ? 'Modification en cours' : 'Enregistrer'" />
  <app-message [message]="validationMessage"> </app-message>
</form>
