import { Pipe, PipeTransform } from '@angular/core'
import * as moment             from 'moment'

@Pipe({
    name: 'messageDate'
})

export class MessageDatePipe implements PipeTransform {

    transform(value: string, format: string = ""): string {
        if (!value || value==="") 
        	return ""

        moment.locale('fr')

        if( format == 'smart' ){
        	const dateToConvert = moment(value).format('L')
	        const today         = moment().format('L')

	        if( dateToConvert == today )
	        	return moment(value).format('HH:mm')
	        else
	        	return moment(value).format('Do MMM HH:mm')
        }
        else
        	return moment(value).format(format)
	        
    }
}
